import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
  REMOVE_FAVORITE_PROFESSIONAL,
  fetchFavoriteProfessionals,
} from '../../../../actions';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import useLoading from '../../../../hooks/useLoading';
import { Colors } from '../../../../themes/colors';
import { CardActionsHeader } from '../CardActionsHeader';
import { ProfessionalCard } from '../ProfessionalCard';
import { EmptyFavorites } from './EmptyFavorites';
import { ProfessionalCardActions } from './ProfessionalCardActions';
import { ProfessionalCardDetails } from './ProfessionalCardDetails';

const actions = [
  FETCH_FAVORITE_PROFESSIONALS,
  REMOVE_FAVORITE_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
];

export const FavoritesList = () => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading(actions);
  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);

  useEffect(() => {
    dispatch(fetchFavoriteProfessionals());
  }, [dispatch]);

  if (isLoading) {
    return <CustomSpinner isLoading size={24} />;
  }

  if (!favoriteProfessionals?.length) {
    return <EmptyFavorites />;
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <span
        style={{
          display: 'flex',
          fontFamily: 'Nunito',
          fontSize: '16px',
          fontStyle: 'italic',
          color: Colors.neutral_500,
          textAlign: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        You can manage your list of favorite professionals on this section of the Professionals Hub,
        <br />
        and directly invite them to your upcoming shifts!
      </span>

      <div
        style={{
          maxWidth: '827px',
          marginTop: 32,
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
          layout
        >
          <AnimatePresence>
            <CardActionsHeader actions={['Invite']} />
            {favoriteProfessionals?.map((professional) => (
              <ProfessionalCard
                key={professional.id}
                user={{ ...professional, isFavorite: true }}
                Details={ProfessionalCardDetails}
                Actions={ProfessionalCardActions}
                showRemoveIcon
              />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};
