import moment from 'moment';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import WarningBanner from '../commonComponents/WarningBanner';
import {
  getShouldFilterRenouncedProcedures,
  showProfessionalAttendanceBadges,
} from '../growthbook';
import defaultProfile from '../images/HY_tooth.png';
import HeartIcon from '../images/icons/Heart';
import ratingIcon from '../images/star_icon.svg';
import { Colors } from '../themes/colors';
import SkillsMismatchPopup from './SkillsMismatchPopup';
import ConfirmPopup from './shared/ConfirmPopup';
import { useCandidate } from './CandidateList/hooks/useCandidate';
import { filterObjectKeys } from '../utils/Object';
import { UserProfession, UserSubProfession } from '../enums/UserProfession';
import { EducationUtils } from '../utils/EducationUtils';
import { CandidateBadges } from './candidates/CandidateBadges';
import { getCompanyMatchLabel } from './CandidateList/utils';
import { CompanyMatchLabel } from './CandidateList/components/UserCard/components/CompanyMatchLabel';
import { OfficeTypeEnum } from '../utils/Enum';

export default function CandidateInfo ({
  isFavorite, candidate, horizontalCard, containerStyle
}) {
  const parentOffice = useSelector((state) => state.user.user);
  const childOffice = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);

  const procedureEndorsement = useSelector((store) => store.job.currentCandidateEndorsement);

  const [showMoreRows, setShowMoreRows] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showProceduresPopup, setShowProceduresPopup] = useState(false);
  const [currentProcedureTitle, setCurrentProcedureTitle] = useState('');

  const job = useSelector((state) => state.job.job);
  const licenseNumber = candidate?.license?.credentials?.find(
    ({ entry_field }) => entry_field?.license,
  )?.entry_field?.license;
  const issueDate = candidate?.license?.credentials?.find(
    ({ entry_field }) => entry_field?.issue_date,
  )?.entry_field?.issue_date;

  const candidateEducation = candidate?.professional_info?.education;
  const candidateProfession = candidate?.license?.profession;
  const candidateSpecialtiesAndProcedures = candidateEducation?.[job?.profession]
    ?.specialtiesAndProcedures

  const specialties = useMemo(() => Object.keys(filterObjectKeys(candidateSpecialtiesAndProcedures, 'specialty')), [candidateSpecialtiesAndProcedures]);

  const procedures = useMemo(() => {
    const procedures = EducationUtils.getProceduresOfProfession({
      specialtiesAndProcedures: candidateSpecialtiesAndProcedures,
      profession: candidateProfession,
      specialties
    })

    if (getShouldFilterRenouncedProcedures() && procedureEndorsement) {
      const endorsements = procedureEndorsement.education?.specialtiesAndProcedures ?? [];

      return EducationUtils.getEndorsedProcedures({
        endorsements,
        procedures,
        profession: candidateProfession,
      });
    }

    return procedures;
  }, [candidateEducation, job?.profession]);

  const specialtiesAndProcedures = Object.values(
    candidateEducation?.[job?.profession]?.specialtiesAndProcedures || {},
  ).flat();

  const hasProcedures = [UserProfession.DA, UserProfession.DN]
    .includes(job?.profession) ? specialtiesAndProcedures.length : procedures.length;

  const candidateInsurances = candidate?.professional_info?.insurances;

  const {
    specialtyMatch,
    insuranceMismatch,
    userSpecialties
  } = useCandidate(childOffice, candidate, job);

  const nationalProviderIdentifier = candidate?.professional_info?.nationalProviderIdentifier;
  const shouldShowNpi = job.profession === UserProfession.DN;

  const isDSO = childOffice?.office_info?.officeType === OfficeTypeEnum.DSO;
  const companyMatchLabel = getCompanyMatchLabel({
    averageRating: candidate?.average_rating,
    hasBadges: !!candidate?.badges?.length || candidate?.currentRank?.rank,
    hasWorkedWithDSO: candidate?.hasWorkedWithDSO,
    hasWorkedWithOffice: candidate?.hasWorkedWithOffice,
    isDSO,
    officeName: isDSO ? parentOffice?.dsoFullName : childOffice?.office_name,
  });

  const professionName = (() => {
    const subProfession = candidate?.license?.subProfession;

    if (subProfession === UserSubProfession.EFDA) {
      return 'Expanded Functions DA';
    }

    return candidate?.license?.professionName;
  })();

  const renderWarningDescription = () => {
    if (job.profession === UserProfession.DN) {
      let description = '';

      if (!specialtyMatch && insuranceMismatch) {
        description = 'This professional does not have experience in all procedures needed or the same Insurance Provider.';
      } else if (!specialtyMatch) {
        description = 'This professional does not have experience in all procedures needed.';
      } else if (insuranceMismatch) {
        description = 'This professional does not have the same Insurance Provider.';
      }

      return <>{description}</>;
    }

    return (
      <>
        This professional does not have your preferred specialty,
        {' '}
        <strong>{job?.specialty?.split('_')?.[1]}</strong>
        .
      </>
    );
  };

  const handleScroll = (event) => {
    const container = event.currentTarget;
    const titles = document.querySelectorAll('.rdh-title');

    let newIndex = -1;
    const { scrollTop, clientHeight } = container;

    if (scrollTop === 0) {
      setCurrentProcedureTitle(titles[0].id);
      return;
    }

    if (scrollTop + clientHeight === container.scrollHeight) {
      setCurrentProcedureTitle(titles[titles.length - 1].id);
      return;
    }

    titles.forEach((title, index) => {
      const rect = title.getBoundingClientRect();
      const scrollSize = container.getBoundingClientRect()?.bottom;

      if (rect.top <= clientHeight * (0.00227 * scrollSize - 0.8904)) {
        newIndex = index;
      }
    });

    if (newIndex !== -1) {
      setCurrentProcedureTitle(titles[newIndex].id);
    }
  };


  const renderProceduresList = () => (
    <div
      onScroll={handleScroll}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: 30,
        maxHeight: 400,
        overflowY: 'auto',
      }}
    >
      <div style={{ width: '80%', alignSelf: 'center' }}>
        {[UserProfession.RDH, UserProfession.FD].includes(job?.profession)
          && procedures.map((name) => (
            <p
              key={name}
              className="global_font"
              style={{ margin: 0, display: 'flex', color: Colors.neutral_500 }}
            >
              {`\u2022  ${name?.split('_')?.[1]}`}
            </p>
          ))}

        {[UserProfession.DN, UserProfession.DA].includes(job?.profession)
          && Object.keys(
            candidateEducation?.[job?.profession]?.specialtiesAndProcedures || {},
          )?.map((name) => (
            <div style={{ paddingBottom: 14 }}>
              <p
                id={name}
                key={name}
                className="global_font bold rdh-title"
                style={{
                  top: 0,
                  position: currentProcedureTitle === name ? 'sticky' : 'relative',
                  margin: '0px 0px 6px 0px',
                  textAlign: 'center',
                  fontSize: 18,
                  color: Colors.primary_500,
                  backgroundColor: Colors.white,
                }}
              >
                {name?.split('_')?.[1]}
              </p>

              {candidateEducation?.[job?.profession]?.specialtiesAndProcedures
                ?.[name]?.map((item) => (
                  <p key={item} className="global_font"
                    style={{ margin: 0, display: 'flex', color: Colors.neutral_500 }}>
                    {`\u2022  ${item?.split('_')?.[1]}`}
                  </p>
                ))}
            </div>
          ))}
      </div>
    </div>
  );

  const renderHygInfoRow = (title, value, showWarning) => {
    if (!value) return <></>;

    const rowCode = title.replaceAll(' ', '-');

    return (
      <div style={{ display: 'flex', flexDirection: 'column', color: Colors.neutral_600 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p style={{ margin: 0 }}>
            <b>{title}</b>
          </p>

          {showWarning && (
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
              xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 8 }}>
              <path fillRule="evenodd" clipRule="evenodd"
                d="M7.90822 1.71858C8.82575 0.0874181 11.1743 0.0874173 12.0918 1.71858L18.7882 13.6232C19.6881 15.2231 18.532 17.1999 16.6964 17.1999H3.30364C1.46806 17.1999 0.311944 15.2231 1.21186 13.6232L7.90822 1.71858ZM11.1999 13.6C11.1999 14.2627 10.6626 14.8 9.9999 14.8C9.33716 14.8 8.7999 14.2627 8.7999 13.6C8.7999 12.9373 9.33716 12.4 9.9999 12.4C10.6626 12.4 11.1999 12.9373 11.1999 13.6ZM9.9999 3.99999C9.33716 3.99999 8.7999 4.53725 8.7999 5.19999V8.79999C8.7999 9.46274 9.33716 9.99999 9.9999 9.99999C10.6626 9.99999 11.1999 9.46274 11.1999 8.79999V5.19999C11.1999 4.53725 10.6626 3.99999 9.9999 3.99999Z"
                fill="#EA7E00" />
            </svg>
          )}
        </div>

        <div style={{ display: 'inline-block' }}>
          <p
            style={{
              margin: 0,
              marginTop: 6,
              lineHeight: 1.2,
              overflow: 'hidden',
            }}
            ref={(ref) => {
              if (!ref) return;

              const divHeight = ref.offsetHeight;
              const lines = Math.round(divHeight / (16 * 1.2));

              if (lines > 2 && !showMoreRows[rowCode]) {
                setShowMoreRows({
                  ...showMoreRows,
                  [rowCode]: { value: false },
                });
              }
            }}
          >
            {showMoreRows[rowCode] && !showMoreRows[rowCode].value
              ? `${value.slice(0, 50)}...` : value}

            {showMoreRows[rowCode] && !showMoreRows[rowCode].value && (
              <span
                onClick={() => {
                  setShowMoreRows({
                    ...showMoreRows,
                    [rowCode]: { value: true },
                  });
                }}
                style={{
                  fontSize: 16,
                  color: Colors.secondary_500,
                  cursor: 'pointer',
                }}
              >
                <b>
                  {'  '}
                  more
                </b>
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: '70%', ...containerStyle }}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: horizontalCard ? 'row' : 'column',
          marginBottom: 20,
          marginLeft: 12,
        }}
      >
        <div
          style={{
            position: 'relative',
            backgroundColor: '#2fb1c7',
            width: 110,
            height: 110,
            borderRadius: 55,
            alignItems: 'center',
            justifyContent: 'center',
            border: isFavorite ? `3px solid ${Colors.favorites_400}` : 'none',
          }}
        >
          {candidate && candidate.profile_image ? (
            <img
              src={candidate.profile_image}
              style={{
                width: 110,
                height: 110,
                borderRadius: 55,
                objectFit: 'cover',
              }}
              alt="profile_image"
            />
          ) : (
            <div className="default_circle_container">
              <img src={defaultProfile} alt="profile_image" width="50px" />
            </div>
          )}
          {isFavorite && (
            <div style={{ position: 'absolute', top: '75%', right: 0 }}>
              <HeartIcon color={Colors.favorites_400} />
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <p
                style={{
                  fontSize: 20,
                  color: Colors.neutral_600,
                  fontFamily: 'Nunito',
                  margin: '0px 7px 0px 0px',
                }}
              >
                <b>
                  {`${candidate?.first_name} ${candidate?.last_name}`}
                </b>
              </p>

              <img
                src={ratingIcon}
                alt="green"
                style={{
                  width: 20,
                  height: 20,
                  margin: '0px 5px 3px 0px',
                }}
              />

              <span
                style={{
                  fontSize: 16,
                  marginRight: 5,
                  color: Colors.neutral_600,
                  fontFamily: 'Nunito',
                }}
              >
                <b>{candidate?.average_rating?.toFixed(1) || '5.0'}</b>
              </span>
            </div>

            <div
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                color: Colors.neutral_600,
              }}
            >
              Drivers License Verified
            </div>

            <div
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                color: Colors.neutral_600,
              }}
            >
              {candidate?.license?.subProfession ?? candidate?.license?.profession}
              {' '}
              Credentials Verified
            </div>
            <CompanyMatchLabel professional={candidate} />
          </div>
        </div>
      </div>

      {!specialtyMatch || insuranceMismatch ? (
        <WarningBanner
          noIcon
          onClick={() => {
            if (!specialtyMatch) {
              setShowModal(true);
            }
          }}
          description={<>{renderWarningDescription()}</>}
          iconStyle={{ marginLeft: 10 }}
          containerStyle={{ width: '100%' }}
          indicatorStyle={{ display: 'none' }}
          primaryColor="#EA7E00"
          secondaryColor="#FEFBF6"
        />
      ) : null}

      {showProfessionalAttendanceBadges() && <CandidateBadges candidate={candidate} />}

      {(candidate?.school || candidate?.graduationDate) && (
        <div style={{ padding: '14px 18px', border: `1px solid ${Colors.neutral_150}` }}>
          {renderHygInfoRow('Education:', candidate?.school)}

          {candidate?.graduationDate && (
            <p
              className="global_font"
              style={{ margin: '6px 0px', fontFamily: 16, color: Colors.neutral_600 }}
            >
              {`Graduation Date: ${moment(candidate?.graduationDate).format('MM/DD/YYYY')}`}
            </p>
          )}
        </div>
      )}

      <div
        style={{
          width: '100%',
          display: 'flex',
          columnGap: 5,
          justifyContent: 'space-between',
          marginTop: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            rowGap: 10,
            width: '80%',
            padding: '14px 18px',
            border: `1px solid ${Colors.neutral_150}`,
          }}
        >
          {renderHygInfoRow('Profession:', professionName)}
          {renderHygInfoRow('Years of Experience:', candidate?.years_of_experience)}

          <div style={{ width: '100%', height: 1, backgroundColor: Colors.neutral_100 }} />

          {companyMatchLabel &&
          <>
            {renderHygInfoRow(`${companyMatchLabel.label}:`, companyMatchLabel.text)}

            <div style={{ width: '100%', height: 1, backgroundColor: Colors.neutral_100 }} />
          </>
          }

          {candidate?.license?.profession !== UserProfession.FD &&
            <>
              <p
                className="global_font"
                style={{ margin: 0, display: 'flex', color: Colors.neutral_600 }}
              >
                <b>License #:</b>
                <p
                  style={{ margin: '0px 0px 0px 5px' }}
                >
                  {licenseNumber}
                </p>
              </p>

              <p
                className="global_font"
                style={{ margin: 0, display: 'flex', color: Colors.neutral_600 }}
              >
                <b>Issued by State of:</b>
                <p
                  style={{ margin: '0px 0px 0px 5px' }}
                >
                  {candidate?.license?.state}
                </p>
              </p>

              {issueDate && (
                <p
                  className="global_font"
                  style={{ margin: 0, display: 'flex', color: Colors.neutral_600 }}
                >
                  <b>Year Issued:</b>
                  <p
                    style={{
                      margin: '0px 0px 0px 5px',
                    }}
                  >
                    {moment(issueDate).format('YYYY')}
                  </p>
                </p>
              )}
            </>
          }

          {nationalProviderIdentifier && shouldShowNpi && (
            <p
              className="global_font"
              style={{ margin: 0, display: 'flex', color: Colors.neutral_600 }}
            >
              <b>NPI:</b>
              <p
                style={{
                  margin: '0px 0px 0px 5px',
                }}
              >
                {nationalProviderIdentifier}
              </p>
            </p>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            rowGap: 10,
            width: '80%',
            padding: '14px 18px',
            border: `1px solid ${Colors.neutral_150}`,
          }}
        >
          {
            (userSpecialties.length > 0 && candidate?.educationUpdated) ?
              renderHygInfoRow(
                'Specialty(ies):',
                EducationUtils.mapToRemoveSpecialtyPrefix(userSpecialties).join(', '),
              ) : null
          }

          {
            (hasProcedures && candidate?.educationUpdated) ?
              renderHygInfoRow(
                'Procedures:',
                EducationUtils.mapToRemoveProcedurePrefix(procedures).join(', '),
              ) : null
          }

          {renderHygInfoRow(
            'Software:',
            candidateEducation?.[job?.profession]?.softwares?.join(', '),
          )}

          {renderHygInfoRow('Insurance:', candidateInsurances?.join(', '), insuranceMismatch)}
        </div>
      </div>

      {showProceduresPopup && (
        <ConfirmPopup
          title="Experienced Procedures"
          rightButtonText="Ok"
          rightButtonAction={() => setShowProceduresPopup(false)}
          modalStyle={{ padding: '45px 0px' }}
        >
          {renderProceduresList()}
        </ConfirmPopup>
      )}

      {showModal && (
        <SkillsMismatchPopup
          onButtonPress={() => setShowModal(false)}
          missingSkills={job?.specialty?.split('_')?.[1]}
        />
      )}
    </div>
  );
};
