import { motion } from 'framer-motion';
import { Colors } from '../../../../themes/colors';
import { RemoteConfigUtils } from '../../../../utils/RemoteConfigUtils';

export const EmptyHighlyRated = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <span style={styles.text}>
      Highly-rated professionals are professionals that you have worked with and received an average
      rating greater than {RemoteConfigUtils.getMinimumHighlyRatedProfessionalRating()} on your last
      shift worked together.
    </span>
    <b style={styles.text}>How to add a professional to your highly-rated list:</b>
    <ul style={{ display: 'flex', flexDirection: 'column', gap: 24, margin: 0 }}>
      <li style={styles.text}>
        <b>End-of-day review:</b> Leave a rating for a professional during the end of the day
        review.
      </li>
      <li style={styles.text}>
        <b>Professional Hub:</b> Add a rating for a professional you recently worked with on the
        “Recent Professionals” tab.
      </li>
    </ul>
  </motion.div>
);

const styles = {
  text: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    color: Colors.neutral_600,
  },
};
