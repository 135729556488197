import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HighlyRatedTab from '../../../images/highly-rated-tab.svg';
import { Colors } from '../../../themes/colors';
import { RemoteConfigUtils } from '../../../utils/RemoteConfigUtils';
import ConfirmPopup from '../../shared/ConfirmPopup';

export function NewHighlyRatedTabPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isBefore('2024-11-15');

  const getStorage = () => {
    const isHighlyRatedTabNew = localStorage.getItem(`isHighlyRatedTabNew-${user?.id}`);

    if (isHighlyRatedTabNew === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`isHighlyRatedTabNew-${user?.id}`, 'false');
    setShowPopup(false);
    window.dispatchEvent(new Event('storage'));
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    showPopup &&
    isSameOrBefore && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Highly-Rated Tab"
            src={HighlyRatedTab}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="Introducing Highly-Rated Professionals!"
        description={
          <>
            We have added a new list of professionals! If you rate a professional above a{' '}
            {RemoteConfigUtils.getMinimumHighlyRatedProfessionalRating()}{' '}
            average rating on your last shift worked together, we will automatically add this
            professional to your highly-rated list. Once they are on this list, you can send them
            direct invites to work again!
            <br />
            <br />
            If you happen to miss a review, no worries! You can now rate professionals on the
            &quot;Recent Professionals&quot; tab as well.
          </>
        }
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32, width: 432 }}
        rightButtonText="Got it!"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
