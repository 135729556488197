import moment from 'moment';
import { UserProfession } from '../../../../enums/UserProfession';
import { Colors } from '../../../../themes/colors';
import { ProfessionalRating } from '../HighlyRatedList/ProfessionalRating';

export const ProfessionalCardDetails = ({ user }) => {
  const { fullname, datetime, profession, rating, jobId } = user;
  const isToday = moment(datetime).isSame(moment(), 'day');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '24px',
        justifyContent: 'center',
        fontSize: 16,
        color: Colors.neutral_600,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
        }}
      >
        <b
          style={{
            fontSize: 22,
            maxWidth: 310,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fullname}
        </b>

        {!isToday && <ProfessionalRating rating={rating} jobId={jobId} />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span
          style={{
            color: Colors.neutral_500,
          }}
        >
          {UserProfession.getName(profession)}
        </span>
        {isToday ? (
          <b
            style={{
              fontSize: 16,
              color: Colors.neutral_500,
            }}
          >
            Working: <span style={{ fontWeight: 500, color: Colors.primary_500 }}>TODAY</span>
          </b>
        ) : (
          <b
            style={{
              fontSize: 16,
              color: Colors.neutral_500,
            }}
          >
            Worked on:{' '}
            <span
              style={{
                fontWeight: 'normal',
                textTransform: 'capitalize',
              }}
            >
              {moment(datetime).format('ddd, MMM DD, YYYY')}
            </span>
          </b>
        )}
      </div>
    </div>
  );
};
