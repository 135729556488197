import moment from 'moment';

function getProfessionName(professions, profession) {
  if (!profession) {
    return 'Dental Hygienist';
  }
  return professions.filter((el) => el.code === profession).map((el) => el.name)[0];
}

function getProfessionCode(professions, professionType) {
  return professions.filter((el) => el.name === professionType).map((el) => el.code)[0];
}

const handleConfirmRemoveDate = (jobDates, deletedDate) => {
  const jobData = {};
  const datesFilteredArr = jobDates.filter((date) => date !== deletedDate);
  datesFilteredArr.forEach((date) => {
    jobData[date] = {};
  });
  return jobData;
};

const sortDates = (dates) => {
  const sortedDates = dates.map((date) => new Date(date)).sort((a, b) => a - b);
  return sortedDates.map((date) => date.toISOString().slice(0, 10));
};

const isDailyOvertime = (job, dailyOvertime) => {
  const { localStart, localEnd, is_break_paid, break_time } = job;
  const offerStartTime = moment(localStart, 'hh:mm A');
  const offerEndTime = moment(localEnd, 'hh:mm A');
  const hoursDifference =
    offerEndTime.diff(offerStartTime, 'hours', true) - (!is_break_paid ? break_time / 60 : 0);
  return hoursDifference > dailyOvertime;
};

const convertTimeToMinutes = (time) => {
  if (time.includes('min')) return parseInt(time, 10);

  if (time.includes('hr')) {
    const hours = parseFloat(time);
    return hours * 60;
  }

  return 0;
};

export {
  getProfessionCode,
  getProfessionName,
  handleConfirmRemoveDate,
  isDailyOvertime,
  sortDates,
  convertTimeToMinutes,
};
