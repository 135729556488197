import { motion } from 'framer-motion';
import Button from '../../../../../commonComponents/Button';
import InputSelect from '../../../../../commonComponents/InputSelect';
import rateIcon from '../../../../../images/rate-icon.svg';
import { Colors } from '../../../../../themes/colors';
import { useRate } from '../../../hooks/useRate';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

export const HourlyRateRow = ({ onToggle, isOpen, defaultValue, onConfirm, averagePrice }) => {
  const {
    selectedRate,
    setOfferRate,
    industrialAverageColor,
    industrialAverageIcon,
    getWarningMessage,
    disabledBtn,
    rates,
  } = useRate({ averagePrice, defaultValue });

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Title text="Hourly Rate:" />
        </div>
      )}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: isOpen || !defaultValue ? Colors.neutral_300 : Colors.neutral_600,
          }}
          text={!isOpen && defaultValue ? `$${defaultValue}` : '$--.--'}
        />
      )}
      icon={rateIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 60,
          marginRight: 60,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
            gap: 12,
            width: 200,
          }}
        >
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 24,
              fontWeight: 700,
              color: Colors.neutral_600,
            }}
          >
            $
          </span>

          <InputSelect
            value={selectedRate}
            setValue={setOfferRate}
            options={rates.map((item) => ({
              title: `${item}.00`,
              value: `${item}.00`,
            }))}
          />
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <motion.img
            key={industrialAverageColor}
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            src={industrialAverageIcon[industrialAverageColor]}
            style={{ marginRight: 6 }}
            alt="green_circle_check"
          />

          {getWarningMessage()}
        </div>

        <span
          style={{
            fontFamily: 'Nunito',
            fontStyle: 'italic',
            fontSize: 15,
            color: Colors.neutral_500,
            textAlign: 'center',
            marginBottom: 28,
            marginTop: 12,
          }}
        >
          Our average hourly rate is based on statewide data, and may not precisely reflect rates in
          your local area.
        </span>

        <Button
          style={{
            width: 154,
            backgroundColor: disabledBtn ? Colors.secondary_100 : Colors.secondary_500,
            marginBottom: 0,
          }}
          onClick={() => {
            onConfirm(selectedRate);
            onToggle();
          }}
          disabled={disabledBtn}
          text="Confirm"
          data-cy="confirm-hourly-rate"
        />
      </div>
    </CollapsibleRow>
  );
};
