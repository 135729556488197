import React from 'react';

const autoFillTooltipDescription = (
  <>
    Professional’s eligibility criteria:
    <br />
    <br />
    {'\u2022  '}
    Has a minimum of 1 year working experience.
    <br />
    {'\u2022  '}
    Has received exceptional performance ratings on over 95% of completed
    shifts.
    <br />
    {'\u2022  '}
    Has completed at least 3 shifts.
    <br />
    <br />
    Note: You may change your auto-confirm preference at any time by visiting the
    Shift Details page.
  </>
);

const arrivalTooltipDescription = 'If you would like the professional to arrive early for office training and/or setup, please account for this within the “Arrival” time.';
const workerClassificationTooltipDescription = (
  <>
    The worker type is determined by the account’s selection for worker type.
    <br />
    <br />
    {'\u2022  '}
    “Employed” means that the professional will be engaged as an employee of our PEO.
    <br />
    <br />
    {'\u2022  '}
    “Contracted” means that an independent contractor has chosen to offer professional services through the GoTu platform.
  </>
);
const departureTooltipDescription = '“Departure” time is when the professional officially stop working and leave the office.';

export {
  arrivalTooltipDescription, autoFillTooltipDescription, departureTooltipDescription, workerClassificationTooltipDescription,
};
