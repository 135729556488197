import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import ratingIcon from '../../../../images/star_icon.svg';
import { Colors } from '../../../../themes/colors';
import { useFixedWidth } from '../../hooks/useFixedWidth';
import { ConfirmOrDecline } from '../ConfirmOrDecline';

export const CollapsibleCandidateHeader = ({ candidate, notesForTemp, show }) => {
  const parentRef = useRef(null);
  const fixedWidth = useFixedWidth(parentRef);
  const job = useSelector((state) => state.job.job);
  const hasMultipleCounters = job?.multipleCounters?.length > 1;

  return (
    <div
      ref={parentRef}
      style={{
        position: 'relative',
      }}
    >
      <AnimatePresence>
        {show && (
          <motion.div
            style={{
              width: fixedWidth,
              position: 'fixed',
              top: hasMultipleCounters ? 133 : 64,
              zIndex: 10,
              backgroundColor: Colors.white,
              boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            }}
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: show ? 1 : 0, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div
              style={{
                position: 'sticky',
                top: 400,
                zIndex: 10,
                backgroundColor: Colors.white,
              }}
            >
              <div
                style={{
                  height: 64,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 40,
                  paddingRight: 80,
                  backgroundColor: Colors.white,
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginLeft: 20 }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <b
                            style={{
                              fontSize: 20,
                              color: Colors.neutral_600,
                              fontFamily: 'Nunito',
                              margin: '0px 7px 0px 0px',
                            }}
                          >
                            {`${candidate?.first_name} ${candidate?.last_name}`}
                          </b>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={ratingIcon}
                              alt="green"
                              style={{
                                width: 20,
                                height: 20,
                                margin: '0px 5px 3px 0px',
                              }}
                            />
                            <b
                              style={{
                                fontSize: 16,
                                marginRight: 5,
                                color: Colors.neutral_600,
                                fontFamily: 'Nunito',
                              }}
                            >
                              {candidate?.average_rating?.toFixed(1) || '5.0'}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ConfirmOrDecline candidateId={candidate?.id} notesForTemp={notesForTemp} small />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
