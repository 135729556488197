import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FEEDBACK_SAVE_ACTION,
  FETCH_JOB_ACTION,
  SEND_PROCEDURE_ENDORSEMENT,
  addFavoriteProfessional,
  blockProfessional,
  feedbackSave,
  fetchJob,
  sendProcedureEndorsement,
} from '../actions';
import { createLoadingSelector } from '../api/selectors';
import Button from '../commonComponents/Button';
import { getAverageRating, isGoodRating } from '../commonComponents/CommonFunctions';
import RatingInput from '../commonComponents/RatingInput';
import {
  getShouldAllowOfficeToPostProcedure,
  getShouldUseMetricsService,
  growthbook,
} from '../growthbook';
import { Colors } from '../themes/colors';
import '../themes/global.scss';
import '../themes/job_summary.scss';
import ProfileSummary from './JobSummary/ProfileSummary';
import Tooltip from './Tooltip';

import CardSpinner from '../commonComponents/CardSpinner';
import BlockCrossIcon from '../images/block-cross.svg';
import BlockUserIcon from '../images/block-user.svg';
import HeartOutlineIcon from '../images/heart-outline.svg';
import ConfirmPopup from './shared/ConfirmPopup';
import { EducationUtils } from '../utils/EducationUtils';
import { SelectableChips } from '../commonComponents/SelectableChips';
import { RatingBadgesFeedback } from './JobSummary/RatingBadgesFeedback';
import { trackEvent } from '../api/analytics';

const JobSummaryRating = ({ match, history }) => {
  const jobId = match?.params?.jobId;
  const location = useLocation();
  const isFromProfessionalHub = location?.state?.isFromProfessionalHub;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const job = useSelector((state) => state.job.job);
  const adjustmentsSend = useSelector((state) => state.job.adjustmentsSend);

  const ratingContainerRef = React.createRef();
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [professionalSkillsRating, setProfessionalSkillsRating] = useState(null);
  const [interpersonalSkillsRating, setInterpersonalSkillsRating] = useState(null);
  const [timeManagementRating, setTimeManagementRating] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [showBlockConfirmPopup, setShowBlockConfirmPopup] = useState(false);
  const [showUnableToAddBlockedModal, setShowUnableToAddBlockedModal] = useState(false);
  const [selectedProcedureIndices, setSelectedProcedureIndices] = useState([]);
  const [ratingBadges, setRatingBadges] = useState([]);

  const metricsServiceEnabled = getShouldUseMetricsService();

  const ratingFeatureValue = growthbook.getFeatureValue('eod-rating-enhancement');
  const isNewRatingMandatory = ratingFeatureValue === 'new-rating-mandatory';
  const isAdjustmensSent = adjustmentsSend.some((id) => id === job?.id);
  const isFavorite = job?.offer_final_reciever?.isFavorite;
  const professionalName = [
    job?.offer_final_reciever?.first_name,
    job?.offer_final_reciever?.last_name,
  ].join(' ');

  const actions = [FETCH_JOB_ACTION, FEEDBACK_SAVE_ACTION, SEND_PROCEDURE_ENDORSEMENT];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const shouldShowProcedureRating = !!professionalSkillsRating && professionalSkillsRating <= 3;
  const {
    procedures,
    offer_final_reciever: offerFinalReceiver
  } = job || {}

  useEffect(() => {
    if (!job || jobId !== job.id) {
      dispatch(fetchJob({ jobId }));
    }
  }, [jobId]);

  useEffect(() => {
    if (!isFromProfessionalHub && jobId === job?.id && job?.isFeedbackSubmited) {
      history.replace(`/dashboard/job/${job.id}/details`);
    }
  }, [job, jobId]);

  useEffect(() => {
    setAverageRating(
      getAverageRating([professionalSkillsRating, interpersonalSkillsRating, timeManagementRating]),
    );
  }, [timeManagementRating, professionalSkillsRating, interpersonalSkillsRating]);

  const sendFeedback = () => {
    setIsSubmitError(false);

    if (isNewRatingMandatory) {
      if (!professionalSkillsRating || !interpersonalSkillsRating || !timeManagementRating) {
        setIsSubmitError(true);
        ratingContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }

    let professionalRating = null;
    if (timeManagementRating || interpersonalSkillsRating || professionalSkillsRating) {
      professionalRating = Object.fromEntries(
        Object.entries({
          timeManagement: timeManagementRating,
          interpersonalSkills: interpersonalSkillsRating,
          professionalSkills: professionalSkillsRating,
        }).filter(([, value]) => value !== undefined && value !== null),
      );
    }

    const data = {
      comment: feedbackMessage,
      hireAgain: 'sure',
      rating: null,
      professionalRating,
      ratingBadges: isGoodRating(averageRating) ? ratingBadges : null,
    };

    if (getShouldAllowOfficeToPostProcedure() && procedures?.length > 0) {
      const endorsement = {
        jobId: job?.id,
        profession: job?.profession,
        specialty: job?.specialty,
        endorsed: (() => {
          if (professionalSkillsRating) {
            if (professionalSkillsRating > 3) {
              return procedures;
            }

            return procedures.filter((_, index) => !selectedProcedureIndices.includes(index));
          }

          return [];
        })(),
        renounced: (() => {
          if (professionalSkillsRating) {
            if (professionalSkillsRating > 3) {
              return [];
            }

            return procedures.filter((_, index) => selectedProcedureIndices.includes(index));
          }

          return [];
        })(),
      };

      if (endorsement.endorsed.length > 0 || endorsement.renounced.length > 0) {
        dispatch(sendProcedureEndorsement({ endorsement, userId: offerFinalReceiver?.id }));
      }
    }

    dispatch(feedbackSave({ data, jobId: job.id, isFromProfessionalHub }));

    trackEvent('New EOD Rating Submitted', {
      job_id: job.id,
      is_mandatory: isNewRatingMandatory,
      time_management: timeManagementRating,
      professional_skills: professionalSkillsRating,
      interpersonal_skills: interpersonalSkillsRating,
      average_rating: averageRating,
      feedback: feedbackMessage,
    });
  };

  const renderRating = () => (
    <>
      <span className="f-dark f-20" style={{ marginTop: 0 }}>
        <b>Please rate this professional{' '}</b>
        {isNewRatingMandatory ? (
          <span className="f-16" style={{ color: Colors.neutral_500 }}>
            (Required)
            <b style={{ color: Colors.error_500 }}>
              *
            </b>
          </span>
        ) : (
          <span className="f-16" style={{ color: Colors.neutral_500 }}>
            (Optional)
          </span>
        )}
      </span>
      <span
        style={{
          color: Colors.neutral_500,
          marginTop: 10,
          marginBottom: 40,
          fontSize: 16,
          fontStyle: 'italic',
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        Your rating will help us improve our capability to connect you with high-quality candidates.
        This won’t be shared with the professional.
      </span>

      <RatingInput
        title="Professional Skills"
        value={professionalSkillsRating}
        onChange={(value) => setProfessionalSkillsRating(value)}
        direction="row"
        isError={isSubmitError && !professionalSkillsRating}
      />

      {
        getShouldAllowOfficeToPostProcedure()
        && shouldShowProcedureRating && !!procedures && procedures.length > 0 && (
          <div style={{
            marginBottom: 32
          }}>
            <p style={{ margin: '2rem 0', fontSize: 18, color: Colors.neutral_600 }}>
              <b>Select any procedure this professional was unable to perform:</b>
            </p>
            <SelectableChips
              style={{ marginBottom: 24 }}
              values={EducationUtils.mapToRemoveProcedurePrefix(procedures)}
              selectedIndices={new Set(selectedProcedureIndices)}
              onItemPressed={(index) => {
                const indices = (() => {
                  const selected = selectedProcedureIndices.includes(index);
                  const collection = selectedProcedureIndices
                  const maxSelection = 4

                  if (selected) {
                    return collection
                      .filter((currentIndex) => currentIndex !== index);
                  }

                  if (!(maxSelection && collection.length >= maxSelection)) {
                    return [...collection, index];
                  }

                  return collection;
                })()

                setSelectedProcedureIndices(indices)
              }}
            />
          </div>
        )
      }

      <RatingInput
        title="Interpersonal Skills"
        value={interpersonalSkillsRating}
        onChange={(value) => setInterpersonalSkillsRating(value)}
        direction="row"
        isError={isSubmitError && !interpersonalSkillsRating}
      />

      <RatingInput
        title="Time Management"
        value={timeManagementRating}
        onChange={(value) => setTimeManagementRating(value)}
        direction="row"
        isError={isSubmitError && !timeManagementRating}
      />
    </>
  );

  const renderProfessionalActions = () => {
    const shouldDisplayFavorite = isGoodRating(averageRating);

    if (shouldDisplayFavorite) {
      const favoriteProfessionals = user?.favoriteProfessionals || [];
      const isUserFavorite = favoriteProfessionals?.includes(job?.offer_final_reciever?.id);

      return (
        <>
          {!isUserFavorite ? (
            <motion.div
              key="favorite_container"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <div>
                <h3
                  className="f-16 f-dark"
                  style={{
                    margin: '40px 0 30px',
                    maxWidth: 330,
                    textAlign: 'center',
                  }}
                >
                  Would you like to add this professional to your Favorites List?
                  <span
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '5px',
                    }}
                  >
                    <Tooltip content="By adding this professional to your Favorites List, you may directly request them for open shifts in the future." />
                  </span>
                </h3>
              </div>

              <button
                type="button"
                className="green-button"
                style={{
                  alignSelf: 'center',
                  width: 220,
                  margin: 0,
                  backgroundColor: Colors.primary_500,
                }}
                onClick={() => {
                  const isBlocked = user?.blocked_hygienist?.includes(
                    job?.offer_final_reciever?.id,
                  );

                  if (isBlocked) {
                    setShowUnableToAddBlockedModal(true);
                  } else {
                    trackEvent('Rate Professional - EOD Screen - Add to Favorites Button Clicked', {
                      shiftId: job?.id,
                      professionalId: job?.offer_final_reciever?.id,
                      rating: job?.offer_final_reciever?.average_rating,
                      yearsOfExperience: job?.offer_final_reciever?.years_of_experience,
                    });

                    dispatch(addFavoriteProfessional(job.offer_final_reciever?.id));
                  }
                }}
              >
                <img src={HeartOutlineIcon} style={{ marginRight: 8 }} alt="heart_outline" />

                <p className="white-text-18" style={{ margin: 0 }}>
                  Add to Favorites
                </p>
              </button>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ marginTop: '60px' }}
            >
              <p
                className="f-18"
                style={{
                  margin: 0,
                  color: Colors.primary_500,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Congrats!
              </p>
              <p style={{ color: Colors.neutral_600 }}>
                This professional is now on your Favorites List.
              </p>
            </motion.div>
          )}
        </>
      );
    }

    return (
      <>
        {!user?.blocked_hygienist?.includes(job?.offer_final_reciever?.id) ? (
          <motion.div
            key="block_container"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div>
              <h3
                className="f-16 global_font f-dark"
                style={{
                  margin: '40px 0 30px 0',
                  width: '330px',
                  textAlign: 'center',
                }}
              >
                Would you like to block this professional from working with you again?
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content="Blocking a professional means that they will not be able to see shifts posted by this office, preventing you from working with them in the future." />
                </span>
              </h3>
            </div>

            <button
              type="button"
              className="green-button"
              style={{
                alignSelf: 'center',
                width: 240,
                margin: 0,
                backgroundColor: Colors.secondary_500,
              }}
              onClick={() => setShowBlockConfirmPopup(true)}
            >
              <img src={BlockUserIcon} style={{ marginRight: 8 }} alt="block_user_icon" />

              <p className="white-text-18" style={{ margin: 0 }}>
                Block Professional
              </p>
            </button>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ marginTop: '60px' }}
          >
            <p
              className="global_font f-18"
              style={{
                margin: 0,
                color: Colors.neutral_600,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Successfully Blocked
            </p>
            <p className="global_font" style={{ color: Colors.neutral_600 }}>
              This professional is now blocked.
            </p>
          </motion.div>
        )}
      </>
    );
  };

  const renderRatingBadgesFeedback = () => {
    const shouldDisplay = metricsServiceEnabled && isGoodRating(averageRating);

    if (!shouldDisplay) {
      return null;
    }

    return (
      <motion.div
        key="rating_badges_feedback_container"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <RatingBadgesFeedback 
          selectedBadges={ratingBadges} 
          onSelectBadge={(selectedBadge) => {
            const badges = ratingBadges.includes(selectedBadge)
              ? ratingBadges.filter((badge) => badge !== selectedBadge)
              : [...ratingBadges, selectedBadge];

            setRatingBadges(badges);
          }} />
      </motion.div>
    );
  };

  if (!job || isLoading) {
    return <CardSpinner />;
  }

  return (
    <>
      <div className="component_container global_font">
        <div style={{ width: '100%' }}>
          <ProfileSummary title="Rate Professional" job={job} noBack={isAdjustmensSent} />

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '-1px 20px 10px 20px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: Colors.white,
                    paddingBlock: 62,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow:
                      '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <div
                    ref={ratingContainerRef}
                    style={{
                      maxWidth: 658,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {renderRating()}
                    {!isFromProfessionalHub && renderProfessionalActions()}
                    {renderRatingBadgesFeedback()}

                    <h3 className="f-18 f-dark" style={{ marginTop: '60px' }}>
                      Confidential Feedback for GoTu{' '}
                      <span className="f-16" style={{ fontWeight: 400, color: Colors.neutral_500 }}>
                        (Optional)
                      </span>
                    </h3>
                    <textarea
                      className="textarea-style"
                      style={{ width: 468 }}
                      placeholder="Enter information"
                      onChange={(e) => setFeedbackMessage(e.target.value)}
                    />
                    <div style={{ margin: '35px 0px' }}>
                      <Button
                        text="Send"
                        textClassName="text-capitalize"
                        onClick={() => sendFeedback()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showUnableToAddBlockedModal && (
        <ConfirmPopup
          title="Unable to Add to Favorites"
          description="To add this professional to your Favorites, please contact Customer Support to remove this professional from your blocked list."
          rightButtonText="Close"
          rightButtonAction={() => setShowUnableToAddBlockedModal(false)}
        />
      )}

      {showBlockConfirmPopup && (
        <ConfirmPopup
          title="Block Professional"
          description={
            isFavorite
              ? // eslint-disable-next-line max-len
                `To block this professional, they must be removed from your Favorites list. Would you like to continue with removing ${professionalName} from your Favorites and blocking them?`
              : 'Are you sure you want to block this professional from working with you again?'
          }
          leftButtonText="Go Back"
          leftButtonAction={() => setShowBlockConfirmPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            trackEvent('Rate Professional - EOD Screen - Block Professional Submitted', {
              shiftId: job?.id,
              professionalId: job?.offer_final_reciever?.id,
              rating: job?.offer_final_reciever?.average_rating,
              yearsOfExperience: job?.offer_final_reciever?.years_of_experience,
            });

            dispatch(blockProfessional({ user: job.offer_final_reciever }));
          }}
          Icon={() => <img src={BlockCrossIcon} style={{ marginBottom: 20 }} alt="block_cross" />}
          closePopup={() => setShowBlockConfirmPopup(false)}
        />
      )}
    </>
  );
};

export default JobSummaryRating;
