import moment from 'moment';
import { useSelector } from 'react-redux';
import { isPartialShift } from '../../../commonComponents/CommonFunctions';
import { shouldUseNewOfferExpiration, showCandidateOvertime } from '../../../growthbook';
import { Colors } from '../../../themes/colors';
import { RemoteConfigUtils } from '../../../utils/RemoteConfigUtils';
import { PartialShift } from '../../CandidateList/components/PartialShift';
import { renderCounter, renderOfferExpirationDateTime } from '../../counterJobs/RenderFunc';
import Tooltip from '../../Tooltip';
import { isEmployeeOptionShift } from '../../WorkerClassification/utils';
import { HighlightedLines } from './HighlightedLines';

const convenienceFeeTooltip =
  'GoTu charges a 3.5% convenience fee on Credit Card transactions. If you do not wish to be charged the convenience fee, please update your billing to E-check/ACH.';

const overtimeTooltip =
  'Overtime pay (1.5x rate) is required for hours that exceed the state’s daily and/or weekly regular working hour limits.';

export const ShiftDetails = ({ currentCounter }) => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const job = useSelector((state) => state.job.job);
  const isCounterHours = isPartialShift(currentCounter);
  const isCounterRate = job?.rate !== currentCounter?.candidateRate;
  const amount = isCounterHours ? currentCounter?.payment?.hygienistPay : job.amount;
  const totalAmount = isCounterHours ? currentCounter?.payment?.totalAmount : job.total;
  const tempmeeFee = isCounterHours ? currentCounter?.payment?.tempmeeFee : job.tm_fee;
  const tempmeeFeePercent = isCounterHours
    ? (currentCounter?.payment?.tempmeeFeeRatio * 100).toFixed(1)
    : job.tm_fee_percent;
  const convenienceFee = isCounterHours
    ? currentCounter?.payment?.convenienceFee
    : job.convenience_fee;
  const convenienceFeePercent = isCounterHours
    ? (currentCounter?.payment?.convenienceFeeRatio * 100).toFixed(1)
    : job.convenience_fee_percent.toFixed(1);
  const workingHours = isCounterHours
    ? moment(currentCounter?.end).diff(moment(currentCounter?.begin), 'hours')
    : job.workingHourls;

  const chargeDate = moment(job.job_datetime).subtract(28, 'days').format('MM/DD/YYYY');
  const startDiff = moment(job.job_datetime).diff(moment(), 'days');

  const professionName = (() => {
    if (job.subProfession === 'EFDA') {
      return 'Expanded Functions DA';
    }

    return job.professionName;
  })();
  const offerExpirationStates = RemoteConfigUtils.getOfferExpirationStates();
  const shouldShowNewOfferExpirationDateTime = offerExpirationStates.includes(
    user?.address?.[0]?.state,
  );

  return (
    <div
      style={{
        display: 'flex',
        columnGap: 20,
        justifyContent: 'center',
        width: '100%',
        marginBottom: 0,
      }}
    >
      <div
        style={{
          flex: 1,
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 24px 15px 24px',
          rowGap: 12,
          backgroundColor: Colors.neutral_50,
          paddingTop: 24,
          paddingBottom: 40,
        }}
      >
        {isCounterHours && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: -8,
              marginTop: 8,
            }}
          >
            <PartialShift />
          </div>
        )}

        {shouldUseNewOfferExpiration() && shouldShowNewOfferExpirationDateTime
          ? renderOfferExpirationDateTime(currentCounter?.expiration, user?.time_zone)
          : renderCounter(isCounterRate, currentCounter?.expiration || new Date())}

        <h1
          className="global_font bold_font"
          style={{
            fontSize: 20,
            margin: '0px 0px 16px 0px',
            color: Colors.primary_500,
            textAlign: 'center',
          }}
        >
          Shift Details
        </h1>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Office:
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            {job.offer_owner?.office_name}
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Date:
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            {job.local_date}
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Profession:
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            {professionName}
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Preferred Specialty:
          </p>
          <p
            className="global_font"
            style={{
              margin: 0,
              color: Colors.neutral_600,
              fontSize: 16,
              textTransform: 'capitalize',
              textAlign: 'right',
            }}
          >
            {job?.specialty?.split('_')?.[1] || 'General'}
          </p>
        </div>

        <HighlightedLines highlight={isCounterHours}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p
                className="global_font bold_font"
                style={{
                  margin: 0,
                  color: Colors.neutral_600,
                  fontSize: 16,
                }}
              >
                Hours:
              </p>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: Colors.neutral_300,
                  height: 'min-content',
                  padding: '0px 5px',
                  marginLeft: 8,
                  borderRadius: 50,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    color: Colors.white,
                    fontFamily: 'Nunito',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  EST
                </p>
              </div>
            </div>

            <p
              className="global_font"
              style={{
                margin: 0,
                color: Colors.neutral_600,
                textDecoration: isCounterHours ? 'line-through' : 'none',
              }}
            >
              {`${job.local_start_time} - ${job.local_end_time}`}
            </p>
          </div>

          {isCounterHours && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p
                className="global_font bold_font"
                style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
              >
                *Counter Offer (hours):
              </p>
              <p
                className="global_font"
                style={{
                  margin: 0,
                  color: Colors.neutral_600,
                  textTransform: 'lowercase',
                  fontWeight: 700,
                }}
              >
                {`${currentCounter?.local_begin} - ${currentCounter?.local_end}`}
              </p>
            </div>
          )}
        </HighlightedLines>

        {isCounterHours && <div style={{ marginTop: -20 }} />}

        <HighlightedLines highlight={job?.rate !== currentCounter?.candidateRate}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              Hourly Rate:
            </p>
            <p
              className="global_font"
              style={{
                margin: 0,
                color: Colors.neutral_600,
                textDecoration:
                  job?.rate !== currentCounter?.candidateRate ? 'line-through' : 'none',
              }}
            >
              {`$${job.rate.toFixed(2)}`}
            </p>
          </div>

          {job?.rate !== currentCounter?.candidateRate ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p
                className="global_font bold_font"
                style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
              >
                *Counter Offer (rate):
              </p>
              <p
                className="global_font"
                style={{ margin: 0, color: Colors.neutral_600, fontWeight: 700 }}
              >
                {`$${currentCounter?.candidateRate?.toFixed(2)}`}
              </p>
            </div>
          ) : (
            <></>
          )}
        </HighlightedLines>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p
            className="global_font bold_font"
            style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
          >
            Break:
          </p>
          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            {job.lunch_final_break_time} min {job.is_lunch_break_paid ? ' (Paid)' : ' (Unpaid)'}
          </p>
        </div>

        {!showCandidateOvertime() ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              {`Professional Pay (${workingHours.toFixed(1)} hrs):`}
            </p>
            <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
              ${amount.toFixed(2)}
            </p>
          </div>
        ) : (
          <HighlightedLines highlight={job?.regularAndOvertimeHours?.overtime > 0}>
            {job?.regularAndOvertimeHours?.regular > 0 && job?.totalRegular > 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  className="global_font bold_font"
                  style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
                >
                  Regular Rate Pay ({job?.regularAndOvertimeHours?.regular?.toFixed(2)} hrs):
                </p>

                <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
                  {`$${job?.totalRegular?.toFixed(2)}`}
                </p>
              </div>
            )}
            {job?.regularAndOvertimeHours?.overtime > 0 && job?.totalOvertime > 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  className="global_font bold_font"
                  style={{
                    margin: 0,
                    color: Colors.neutral_600,
                    fontSize: 16,
                    display: 'flex',
                  }}
                >
                  *Overtime Pay ({job?.regularAndOvertimeHours?.overtime?.toFixed(2)} hrs):
                  <span
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      bottom: '2.5px',
                      marginBottom: -10,
                    }}
                  >
                    <Tooltip content={overtimeTooltip} />
                  </span>
                </p>

                <p
                  className="global_font"
                  style={{ margin: 0, color: Colors.neutral_600, fontWeight: 700 }}
                >
                  {`$${job?.totalOvertime?.toFixed(2)}`}
                </p>
              </div>
            )}
          </HighlightedLines>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {tempmeeFeePercent !== 0 ? (
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              Service Fee ({tempmeeFeePercent}
              %):
            </p>
          ) : (
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              Service Fee:
            </p>
          )}

          <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
            {`$${tempmeeFee.toFixed(2)}`}
          </p>
        </div>
        {job.employeeOptionFee !== 0 && isEmployeeOptionShift(job) && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              Employee Fee:
            </p>
            <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
              {job.employeeOptionFee ? `$${job.employeeOptionFee?.toFixed(2)}` : '$--.--'}
            </p>
          </div>
        )}

        {job.convenience_fee !== 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: -8,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p
                className="global_font bold_font"
                style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
              >
                Convenience Fee {`(${convenienceFeePercent || '0'}%)`}:
              </p>
              <span
                style={{
                  marginLeft: '5px',
                  position: 'relative',
                  bottom: '2.5px',
                }}
              >
                <Tooltip content={convenienceFeeTooltip} />
              </span>
            </div>
            <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
              {`$${convenienceFee.toFixed(2) || '0'}`}
            </p>
          </div>
        ) : null}

        {job?.multi_day_booking && startDiff > 28 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              className="global_font bold_font"
              style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
            >
              Professional Pay Charge Date:
            </p>
            <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
              {chargeDate}
            </p>
          </div>
        ) : null}

        {job.counterCover ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p
                className="global_font bold_font"
                style={{ margin: 0, color: Colors.neutral_600, fontSize: 16 }}
              >
                Gotu Counter Cover:
              </p>
            </div>
            <p className="global_font" style={{ margin: 0, color: Colors.neutral_600 }}>
              -$ {job.counterCover.amount.toFixed(2)}
            </p>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p
            className="global_font bold_font"
            style={{
              margin: 0,
              color: Colors.neutral_600,
              display: 'flex',
              alignItems: 'center',
              columnGap: 5,
              fontSize: 16,
            }}
          >
            <p style={{ color: Colors.success_500, margin: 0 }}>Total Pay:</p>
          </p>

          <p className="global_font bold" style={{ margin: 0, color: Colors.neutral_600 }}>
            ${totalAmount.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};
