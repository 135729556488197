import React, { useState, useEffect, useMemo } from 'react';
import '../../../themes/global.scss';
import '../../../themes/home.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RemoteConfigUtils } from '../../../utils/RemoteConfigUtils';
import Tooltip from "../../Tooltip";
import useLoading from '../../../hooks/useLoading';
import CardSpinner from '../../../commonComponents/CardSpinner';
import { RadioOption } from '../../registration/components/RadioOption';
import MobileAppModal from '../../../commonComponents/MobileAppModal';
import {
  editJob, fetchJob, getUserEducation,
} from '../../../actions';
import { Colors } from '../../../themes/colors';

import arrow from '../../../images/Right_arrow.png';

import ModalRatePopup from '../../ModalRatePopup/ModalRatePopup';
import ShowStartTime from '../../../commonComponents/showStartTime';
import BackButton from '../../../commonComponents/BackButton';
import CalendarPopup from '../../../commonComponents/CalendarPopup';
import Button from '../../../commonComponents/Button';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { EducationUtils } from '../../../utils/EducationUtils';
import {
  getShouldAllowOfficeToPostProcedure,
} from '../../../growthbook';
import { SelectableChips } from '../../../commonComponents/SelectableChips';
import { AutoConfirmOptions } from '../multiday/components/Rows/AutoConfirmRow';
import { getAutoFillInfo } from '../../../constants';

const EditJobPostFunction = ({ match }) => {
  const dispatch = useDispatch();
  const job = useSelector((state) => state.job.job);
  const averageRating = useSelector((state) => state.job?.averageRating);
  const userEducation = useSelector((state) => state.user.userEducation);
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);

  const {
    specialty,
    procedures,
    subProfession,
    professionName,
    profession,
  } = job || {};

  const specialtiesAndProcedures = userEducation?.[profession]?.specialtiesAndProcedures;
  const { isLoading } = useLoading(['EDIT_JOB_ACTION']);
  const [offerStartDate, setOfferStartDate] = useState(null);
  const [offerStartTime, setOfferStartTime] = useState(null);
  const [offerEndTime, setOfferEndTime] = useState(null);
  const [offerRate, setOfferRate] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [showStartTime, setShowStartTime] = useState(false);
  const [content, setContent] = useState(null);
  const [resultFromModal, setResultFromModal] = useState(null);
  const [breakTime, setBreakTime] = useState(null);
  const [isBreakPaid, setIsBreakPaid] = useState(false);
  const [isEnabled, setIsEnabled] = useState({
    eligibleProfessionals: false,
    favorites: false,
    highlyRated: false,
  });

  const [showCalendarPopup, setShowCalendarPopup] = useState(false);
  const [selectedProcedureIndices, setSelectedProcedureIndices] = useState([]);
  const [isAssistedHygiene, setIsAssistedHygiene] = useState(
    selectedChildOffice.office_info.isAssistedHygiene,
  );

  const availableProcedures = useMemo(() => EducationUtils.getProceduresOfProfession({
    specialtiesAndProcedures,
    profession: UserProfession.getNormalizedCode(profession),
    specialties: [specialty],
  }), [specialty, profession, specialtiesAndProcedures]);

  const profRate = averageRating[job?.profession] || 0;
  const professionTypeCode = UserProfession.getNormalizedCode(profession);
  const autoFillInfo = getAutoFillInfo(UserProfession.getName(profession), specialty);

  // updates selected procedures when available procedures is available
  // or not empty
  //
  // this, to handle `userEducation` might still load when editing occurs
  useEffect(() => {
    if (availableProcedures && availableProcedures.length > 0
      && procedures && procedures.length > 0) {
      const defaultProcedureIndices = procedures
        .map((procedure) => availableProcedures
          .findIndex((currentProcedure) => procedure === currentProcedure))
        .filter((procedureIndex) => procedureIndex > -1);

      setSelectedProcedureIndices(defaultProcedureIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableProcedures]);

  useEffect(() => {
    if (job) {
      setOfferStartDate(moment(`${job.local_date} ${job.local_start_time}`, 'ddd, MMM DD, YYYY hh:mm a').toDate());
      setOfferStartTime(moment(`${job.local_date} ${job.local_start_time}`, 'ddd, MMM DD, YYYY hh:mm a').toDate());
      setOfferEndTime(moment(`${job.local_date} ${job.local_end_time}`, 'ddd, MMM DD, YYYY hh:mm a').toDate());
      setOfferRate(job.rate.toFixed(2));
      setBreakTime(job.lunch_break_time);
      setIsBreakPaid(job.is_lunch_break_paid);
      setIsEnabled({
        eligibleProfessionals: job.autoFill,
        favorites: job?.autofillOptions?.favorite,
        highlyRated: job?.autofillOptions?.highlyRated,
      })
      setIsAssistedHygiene(job.isAssistedHygiene);
    } else {
      const { jobId } = match.params;

      dispatch(fetchJob({ jobId }));
    }

    dispatch(getUserEducation({ profession }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  useEffect(() => {
    if (content === 'start_time') {
      setOfferStartTime(resultFromModal);
    } else if (content === 'end_time') {
      setOfferEndTime(resultFromModal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultFromModal]);

  const editOffer = () => {
    const localDate = moment(offerStartDate).format('yyyy-MM-DD');
    const jobData = {
      localDate,
      localStart: moment(offerStartTime).format('hh:mm a'),
      localEnd: moment(offerEndTime).format('hh:mm a'),
      rate: parseFloat(offerRate),
      lunch_break_time: parseInt(breakTime, 0),
      is_lunch_break_paid: isBreakPaid,
      autoFill: isEnabled?.eligibleProfessionals,
      autoFillFavorites: isEnabled?.favorites,
      autoFillHighlyRated: isEnabled?.highlyRated,
      procedures: selectedProcedureIndices.map((index) => availableProcedures[index]),
      ...(professionTypeCode === UserProfession.RDH && {
        isAssistedHygiene,
      }),
    };

    dispatch(editJob({ jobId: job.id, jobData }));
  };

  const tooltipDescription = (
    <>
      Professional’s eligibility criteria:
      <br />
      <br />
      {'\u2022  '}
      Has at least a {RemoteConfigUtils.getAutofillMinAttendanceScore()}% attendance score for
      previous shifts worked.
      <br />
      {'\u2022  '}
      Has a minimum of 1 year working experience.
      <br />
      {'\u2022  '}
      Has received exceptional performance ratings on over 95% of completed shifts.
      <br />
      {'\u2022  '}
      Has completed at least 3 shifts.
      <br />
      <br />
      Note: You may change your auto-confirm preference at any time by visiting the Shift Details
      page.
    </>
  );

  const changeBreakTime = (event) => {
    setBreakTime(event.time);
    setIsBreakPaid(event.paid);
  };

  const showContent = (type) => {
    if (type === 'start_time' || type === 'end_time') {
      setContent(type);
      setModalIsOpen(true);
      setShowStartTime(true);
    } else if (type === 'break') {
      setContent(type);
      setResultFromModal(() => changeBreakTime);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShowStartTime(false);
  };

  const startTime = (event) => {
    setOfferStartTime(moment(event, 'hh:mm aa'));
  };

  const endTime = (event) => {
    setOfferEndTime(moment(event, 'hh:mm aa'));
  };

  const offerRateChange = (event) => {
    setOfferRate(event);
  };

  const showTime = (type) => {
    setShowStartTime(true);
    if (type === 'start_time') {
      setResultFromModal(() => startTime);
    } else if (type === 'end_time') {
      setResultFromModal(() => endTime);
    }
    setContent(type);
  };

  const renderRow = (title, value, onClick, hasArrow, content) => (
    <div
      style={{
        cursor: hasArrow ? 'pointer' : 'default',
        borderBottom: `1px solid ${Colors.neutral_100}`,
      }}
    >
      <div className="row_container" onClick={onClick}>
        <div
          className="row_container"
          style={{ width: '95%', cursor: hasArrow ? 'pointer' : 'default' }}>
          <p className="f-dark f-18"><b>{title}</b></p>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <p className="f-dark f-16">{value}</p>
          </div>
        </div>
        {hasArrow && (
          <img alt="arrow icon" src={arrow} style={{ marginLeft: 30 }} />
        )}
      </div>

      {content}
    </div>
  );

  let modalDefault = '';
  if (showStartTime) {
    modalDefault = content === 'start_time' ? offerStartTime : offerEndTime;
  }

  if (!job || isLoading) {
    return (
      <CardSpinner />
    );
  }

  return (
    <div className="component_container">
      <div className="floating_container">
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
        }}
        >
          <div style={{
            width: '100%', display: 'flex', marginLeft: 50, marginTop: 50,
          }}
          >
            <BackButton />
          </div>
          <div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: 500,
            }}>
              <h1
                style={{
                  color: Colors.neutral_600,
                  fontSize: 32,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <b>Edit Shift</b>
              </h1>
              {renderRow(
                'Office:',
                job?.offer_owner?.office_name,
                () => {
                },
                false,
              )}

              <div
                className="row_container"
                onClick={() => setShowCalendarPopup(true)}
                style={{ cursor: 'pointer' }}
              >
                <div className="row_container" style={{
                  width: '95%',
                  borderBottom: `1px solid ${Colors.neutral_100}`,
                  cursor: 'pointer',
                }}>
                  <p className="f-dark f-18"><b>Date:</b></p>
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <p className="f-dark f-16">
                      {' '}
                      {offerStartDate ? moment(offerStartDate).format('ddd, MMM DD, YYYY') : 'Date'}
                    </p>
                  </div>
                </div>
                <img alt="arrow icon" src={arrow} style={{ marginLeft: 30 }} />
              </div>

              {renderRow(
                'Profession:',
                UserSubProfession.getName(subProfession) ?? professionName,
                () => {
                },
                false,
              )}
              {renderRow(
                'Preferred Specialty:',
                specialty?.split('_')?.[1] || 'General',
                () => {
                },
                false,
              )}

              {professionTypeCode === UserProfession.RDH &&
                renderRow(
                  'Assisted Hygiene Available:',
                  '',
                  () => {
                  },
                  false,
                  (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        width: '100%',
                        marginTop: -32,
                        marginBottom: 16,
                      }}
                    >
                      <div
                        style={{
                          marginRight: 68,
                        }}
                      >
                        <div style={{ marginBottom: 10 }}>
                          <RadioOption
                            onClick={() => setIsAssistedHygiene(true)}
                            text="Yes"
                            checked={isAssistedHygiene === true}
                          />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                          <RadioOption
                            onClick={() => setIsAssistedHygiene(false)}
                            text="No"
                            checked={isAssistedHygiene === false}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                )}

              {
                getShouldAllowOfficeToPostProcedure() &&
                availableProcedures?.length > 0 && (
                  renderRow(
                    'Shift Procedure(s):',
                    '',
                    () => {
                    },
                    false,
                    (
                      <SelectableChips
                        style={{ marginBottom: 24 }}
                        values={EducationUtils.mapToRemoveProcedurePrefix(availableProcedures)}
                        selectedIndices={new Set(selectedProcedureIndices)}
                        onItemPressed={(index) => {
                          const indices = (() => {
                            const selected = selectedProcedureIndices.includes(index);
                            const collection = selectedProcedureIndices;
                            const maxSelection = 4;

                            if (selected) {
                              return collection
                                .filter((currentIndex) => currentIndex !== index);
                            }

                            if (!(maxSelection && collection.length >= maxSelection)) {
                              return [...collection, index];
                            }

                            return collection;
                          })();

                          setSelectedProcedureIndices(indices);
                        }}
                      />
                    ),
                  )
                )
              }

              {renderRow(
                'Arrival:',
                offerStartTime ? moment(offerStartTime).format('hh:mm a') : 'Time',
                () => showTime('start_time'),
                true,
              )}
              {renderRow(
                'Departure:',
                offerEndTime ? moment(offerEndTime).format('hh:mm a') : 'Time',
                () => showTime('end_time'),
                true,
              )}
              {renderRow(
                'Hourly Rate:',
                offerRate ? `$ ${offerRate}` : '$ ⏤.⏤',
                () => setShowRatePopup(true),
                true,
              )}
              {renderRow(
                `Break ${isBreakPaid ? '(Paid)' : '(Unpaid)'}:`,
                `${breakTime} min`,
                () => showContent('break'),
                true,
              )}
              <div
                className="row_container"
                style={{
                  cursor: 'default',
                  borderBottom: `1px solid ${Colors.neutral_100}`,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  className="row_container"
                  style={{
                    width: '95%',
                    cursor: 'inherit',
                    alignItems: 'flex-start',
                    paddingTop: 16,
                    paddingBottom: 24,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <b style={{ color: Colors.neutral_600, fontSize: 18, }}>Auto-Confirm:</b>
                    <div
                      style={{
                        marginLeft: 8,
                        position: 'relative',
                        top: '2px',
                      }}
                    >
                      <Tooltip content={tooltipDescription} />
                    </div>
                  </div>

                  {autoFillInfo?.show && 
                  <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', gap: 12 }}
                  >
                    <AutoConfirmOptions
                      options={[
                        {
                          key: 'favorites',
                          value: isEnabled.favorites,
                          label: 'Favorites',
                        },
                        {
                          key: 'highlyRated',
                          value: isEnabled.highlyRated,
                          label: 'Your Highly-Rated',
                        },
                        {
                          key: 'eligibleProfessionals',
                          value: isEnabled.eligibleProfessionals,
                          label: 'Eligible Professionals',
                        },
                      ]}
                      onChange={setIsEnabled}
                    />
                  </div>
                  }
                </div>
                <span style={{
                  color: Colors.neutral_500,
                  fontSize: 15,
                  paddingBottom: 16,
                }}>
                  {autoFillInfo?.message}
                </span>
              </div>

              <div style={{ marginTop: 100, marginBottom: 50, alignSelf: 'center' }}>
                <Button
                  text="Save Changes"
                  textClassName="text-capitalize"
                  onClick={() => editOffer()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileAppModal
        show={modalIsOpen}
        closeModal={closeModal}
        height={973}
        defaultValue={modalDefault}
        content={content}
        result={resultFromModal}
      />

      {showCalendarPopup ? (
        <CalendarPopup
          closeModal={() => setShowCalendarPopup(false)}
          confirmDateBtn={(date) => setOfferStartDate(date)}
        />
      ) : (
        <div />
      )}

      {showStartTime && (
        <ShowStartTime
          content={content}
          result={resultFromModal}
          closeModal={closeModal}
          height={973}
          defaultStartTime={offerStartTime}
          defaultEndTime={offerEndTime}
        />
      )}
      {showRatePopup && (
        <ModalRatePopup
          closeModal={() => setShowRatePopup(false)}
          submitRate={(value) => offerRateChange(value)}
          averagePrice={profRate}
          selectedProfession={job.profession}
          defaultValue={offerRate}
        />
      )}
    </div>
  );
};

export default EditJobPostFunction;
