import '../../themes/global.scss';
import React from 'react';
import moment from 'moment';

import Tooltip from "../Tooltip";
import { Colors } from '../../themes/colors';
import ratingIcon from '../../images/star_Green.png';
import driverLicense from '../../images/driver-license.png';
import verifiedBadge from '../../images/verified_badge.svg';
import TimerCountdown from '../../commonComponents/TimerCountdown';
import statesToHideLicenseCard from './statesToHideLicenseCard';
import { UserSubProfession } from '../../enums/UserProfession';

export const renderHygInfo = (job, candidateId) => {
  const hygInfo = candidateId ? job?.multipleCounters?.find(
    ({ candidate }) => candidate?.id === candidateId,
  )?.candidate : job.candidate;

  if (!hygInfo) {
    return <div />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        marginBottom: 20,
      }}
    >
      <div
        style={{
          backgroundColor: '#2fb1c7',
          width: 110,
          height: 110,
          borderRadius: 55,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {hygInfo && hygInfo.profile_image ? (
          <img
            src={hygInfo.profile_image}
            style={{
              width: 110,
              height: 110,
              borderRadius: 55,
              objectFit: 'cover',
            }}
            alt="profile_image"
          />
        ) : (
          <div className="default_circle_container">
            <img
              src={require('../../images/HY_tooth.png')}
              alt="profile_image"
              width="50px"
            />
          </div>
        )}
      </div>
      <div style={{ marginLeft: 20 }}>
        <p
          style={{
            fontSize: 18,
            color: Colors.neutral_600,
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            marginBottom: 5,
          }}
        >
          {`${hygInfo?.first_name} ${hygInfo?.last_name}` || ''}
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 10,
          }}
        >
          <img
            src={ratingIcon}
            alt="green"
            style={{ width: 15, height: 15, margin: '0px 3px 3px 0px' }}
          />
          <span
            style={{
              fontSize: 14,
              marginRight: 5,
              color: Colors.neutral_500,
              fontFamily: 'Nunito',
            }}
          >
            {hygInfo?.average_rating || '5.0'}
          </span>

          <span
            style={{
              fontSize: 14,
              color: Colors.neutral_500,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
            }}
          >
            {UserSubProfession.getName(job.subProfession) ?? job.professionName}
          </span>
        </div>
        {hygInfo?.license?.license ? (
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 14,
              color: Colors.neutral_500,
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            { `License: ${hygInfo?.license?.license || ''}`}
          </span>
        ) : (
          <div />
        )}

        <div
          style={{
            fontFamily: 'Nunito',
            fontSize: 14,
            color: Colors.neutral_500,
          }}
        >
          {hygInfo?.address[0]?.formatted_address || ''}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <img
            src={driverLicense}
            style={{ objectFit: 'cover', width: 30 }}
            alt="driver-license"
          />

          <div
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 12,
              color: Colors.primary_500,
            }}
          >
            Drivers License Verified
          </div>
        </div>

        {job.profession === 'DA' && statesToHideLicenseCard.includes(hygInfo?.license?.state) && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <img
              src={verifiedBadge}
              style={{ objectFit: 'cover', width: 20 }}
              alt="driver-license"
            />

            <div
              style={{
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                fontSize: 12,
                color: Colors.primary_500,
              }}
            >
              DA License Verified
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const renderHygSkillsInfo = (job, candidateId) => {
  const hygInfo = candidateId ? job?.multipleCounters?.find(
    ({ candidate }) => candidate?.id === candidateId,
  )?.candidate : job.candidate;

  const renderRow = (title, value) => value && (
    <div style={{ width: '100%', display: 'flex', gap: 10 }}>
      <div
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          color: Colors.neutral_600,
          fontSize: 16,
        }}
      >
        {title}
      </div>

      <div
        style={{
          fontFamily: 'Nunito',
          color: Colors.neutral_500,
          fontSize: 16,
        }}
      >
        {value}
      </div>
    </div>
  );

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        marginTop: 25,
        marginBottom: 10,
        marginLeft: 20,
      }}
    >
      {renderRow('Years of Experience:', hygInfo?.years_of_experience)}
      {renderRow('Education:', hygInfo?.school)}

      { hygInfo?.graduationDate ? renderRow(
        'Graduation Date:',
        moment(hygInfo?.graduationDate).format('MM/DD/YYYY'),
      ) : <div />}
    </div>
  );
};

export const licenseCardView = (job, candidateId) => {
  const requestor = candidateId ? job?.multipleCounters?.find(
    ({ candidate }) => candidate?.id === candidateId,
  )?.candidate : job.best_counter?.requestor;

  if (statesToHideLicenseCard.includes(requestor?.license?.state) && job.profession === 'DA') {
    return <></>;
  }

  const verifiedLicense = requestor?.licenses?.find(({ status }) => status === 'verified');

  const stateLicense = verifiedLicense?.credentials?.find(({ credential_type }) => credential_type === 'state_license')?.entry_field;
  const licenseNumber = stateLicense?.license;
  const issueDate = stateLicense?.issue_date;

  const renderRow = (title, value) => (
    <div style={{ margin: 5, width: '100%' }}>
      <div
        style={{
          fontSize: 13,
          color: Colors.neutral_600,
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          marginBottom: 7,
          marginTop: 7,
        }}
      >
        {title}
      </div>
      <div style={{ fontSize: 14, color: Colors.neutral_500, fontFamily: 'Nunito' }}>{value}</div>
      <div
        style={{
          height: 1,
          marginTop: 1,
          backgroundColor: Colors.neutral_300,
          width: '90%',
        }}
      />
    </div>
  );

  return (
    <div style={{ backgroundColor: '#F5F5F5', borderRadius: 10, width: 400 }}>
      <div
        style={{
          backgroundColor: 'rgb(68,178,200)',
          height: 32,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            color: Colors.white,
            fontFamily: 'Nunito',
            fontWeight: 'bold',
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          {`${(UserSubProfession.getName(job.subProfession) || job.professionName || '').toUpperCase()}`}
          {' '}
          LICENSE
        </div>
      </div>
      <div
        style={{
          flexDirection: 'row',
          paddingBottom: 20,
          paddingTop: 10,
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '45%',
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {renderRow('CANDIDATE NAME:', `${requestor?.first_name} ${requestor?.last_name}`)}

          {renderRow('ISSUED BY STATE OF:', verifiedLicense?.state || '')}

          {verifiedLicense?.status ? renderRow(' LIC. STATUS:', verifiedLicense?.status) : <div />}
        </div>

        <div
          style={{
            width: '45%',
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          {licenseNumber ? renderRow('LICENSE #:', licenseNumber || '') : <div />}

          {issueDate ? renderRow('YEAR ISSUED:', moment(issueDate).format('YYYY') || '') : <div />}
        </div>
      </div>
    </div>
  );
};

export const renderCounter = (isCounter, expireTime) => (
  <div
    style={{
      height: 88,
      width: 268,
      alignSelf: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 15,
      marginBottom: 20,
      backgroundColor: Colors.white,
      borderRadius: 8, 
      border: `1px solid ${Colors.neutral_150}`,
    }}
  >
    <p
      className="global_font f-dark"
      style={{
        fontSize: 14,
        fontWeight: 700,
        margin: 0,
        marginTop: 7,
      }}
    >
      {isCounter ? 'COUNTER' : ''}
      {' '}
      OFFER EXPIRES IN
    </p>
    <TimerCountdown
      expireTime={expireTime}
      allowFontScaling
      style={{
        color: Colors.error_500,
        fontSize: 32,
        textAlign: 'center',
        fontFamily: 'Nunito',
        marginVertical: 10,
      }}
    />
  </div>
);

export const renderOfferExpirationDateTime = (expirationTime, timezone) => {
  const date = moment.tz(expirationTime, timezone);
  const isToday = date.isSame(moment(), 'day');
  
  return (
    <div
      style={{
        width: 268,
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 20,
        backgroundColor: Colors.white,
        borderRadius: 8, 
        border: `1px solid ${Colors.neutral_150}`,
        padding: '12px 0',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'center',
          marginBottom: 5,
          paddingLeft: 16
        }}
      >
        <span
          className="global_font f-dark"
          style={{
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            marginTop: 7,
            textTransform: 'uppercase',
            textAlign: 'center'
          }}
        >
          {isToday ? 'OFFER EXPIRES TODAY AT' : 'OFFER EXPIRES ON:'}
          {!isToday && <span><br/>{date.format('ddd, MMM DD, YYYY')} AT</span>}
        </span>
        <span
          style={{
            marginLeft: '4px',
            position: 'relative',
            top: '6px',
          }}
        >
          <Tooltip content="All offers will expire at 7:00 pm (local time) on the day after the offer was submitted. Offers made on Friday and over the weekend will expire on Monday at 7:00 pm." />
        </span>
      </div>
      <span
        style={{
          color: Colors.error_500,
          fontSize: 32,
          textAlign: 'center',
          fontFamily: 'Nunito',
          marginVertical: 10,
        }}
      >
        {date.format('hh:mm A')}
      </span>
    </div>
  )
}