import { useState } from 'react';
import { useSelector } from 'react-redux';
import WarningBannerComponent from '../../../commonComponents/WarningBanner';
import { UserProfession } from '../../../enums/UserProfession';
import { Colors } from '../../../themes/colors';
import { EducationUtils } from '../../../utils/EducationUtils';
import { useCandidate } from '../../CandidateList/hooks/useCandidate';
import SkillsMismatchPopup from '../../SkillsMismatchPopup';

export const WarningBanner = ({ candidate, job }) => {
  const user = useSelector((state) => state.user.user);
  const { specialtyMatch, insuranceMismatch } = useCandidate(user, candidate, job);
  const [showModal, setShowModal] = useState(false);

  const renderWarningDescription = () => {
    if (job.profession === UserProfession.DN) {
      let description = '';

      if (!specialtyMatch && insuranceMismatch) {
        description =
          'This professional does not have experience in all procedures needed or the same Insurance Provider.';
      } else if (!specialtyMatch) {
        description = 'This professional does not have experience in all procedures needed.';
      } else if (insuranceMismatch) {
        description = 'This professional does not have the same Insurance Provider.';
      }

      return <>{description}</>;
    }

    return (
      <>
        This professional does not have your preferred specialty,{' '}
        <strong>{EducationUtils.removeSpecialtyPrefix(job?.specialty)}</strong>.
      </>
    );
  };

  return !specialtyMatch || insuranceMismatch ? (
    <>
      <WarningBannerComponent
        onClick={() => {
          if (!specialtyMatch) {
            setShowModal(true);
          }
        }}
        description={<>{renderWarningDescription()}</>}
        iconStyle={{ marginLeft: 10 }}
        containerStyle={{ width: '100%' }}
        indicatorStyle={{ display: 'none' }}
        primaryColor={Colors.warning_700}
        secondaryColor="#FEFBF6"
      />

      {showModal && (
        <SkillsMismatchPopup
          onButtonPress={() => setShowModal(false)}
          missingSkills={EducationUtils.removeSpecialtyPrefix(job?.specialty)}  
        />
      )}
    </>
  ) : null;
};
