import { useDispatch } from 'react-redux';
import { addFavoriteProfessional } from '../../../../actions';
import { trackEvent } from '../../../../api/analytics';
import { IconButton } from '../../../../commonComponents/IconButton';
import heartOutline from '../../../../images/heart-outline.svg';
import { Colors } from '../../../../themes/colors';
import { InviteButton } from '../InviteButton';

export const ProfessionalCardActions = ({ user }) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 30,
        marginRight: 44,
      }}
    >
      <InviteButton
        user={{
          id: user?.id,
          image: user?.profileImage,
          name: user?.fullname,
          profession: user?.profession,
        }}
      />
      <IconButton
        buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.secondary_500 }}
        imgStyle={styles.imgStyle}
        src={heartOutline}
        tooltipContent="Favorite"
        onClick={() => {
          trackEvent('Professional Hub - Recent - Favorite Button Clicked', {  professionalId: user?.id });
          dispatch(addFavoriteProfessional(user?.id));
        }}
      />
    </div>
  );
};

const styles = {
  buttonStyle: {
    display: 'flex',
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.primary_500,
  },
  imgStyle: {
    width: 24,
    height: 24,
  },
};
