import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeFavoriteProfessional } from '../../../actions';
import StarIcon from '../../../images/icons/Star';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';

const getBorderColor = (isAvailable, isFavorite) => {
  if (isAvailable && isFavorite) {
    return Colors.favorites_400;
  } 
  if (!isAvailable) {
    return Colors.neutral_300;
  }
  return '';
}

export const ProfessionalCard = ({ user, Details, Actions, showRemoveIcon }) => {
  const dispatch = useDispatch();
  const { isAvailable, isFavorite } = user;
  const borderColor = getBorderColor(isAvailable, isFavorite);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleShowRemoveModal = () => {
    setShowRemoveModal(!showRemoveModal);
  };

  return (
    <motion.div
      key={user.id}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
        exit: { duration: 0.2 },
        layout: { duration: 0.3 },
      }}
      layout
      style={{
        width: 700,
        height: 98,
        position: 'relative',
        display: 'flex',
        backgroundColor: Colors.neutral_50,
        borderRadius: '16px',
        borderTopLeftRadius: 84,
        borderBottomLeftRadius: 84,
        boxShadow: isAvailable ? '0px 2px 4px 0px rgba(111, 131, 141, 0.33)' : '',
      }}
    >
      <img
        src={user.profileImage}
        alt="Profile"
        style={{
          filter: isAvailable ? 'none' : 'grayscale(100%)',
          opacity: isAvailable ? 1 : 0.5,
          width: 98,
          height: 98,
          borderRadius: '50%',
          ...(borderColor && {
            border: `3px solid ${borderColor}`,
            width: 92,
            height: 92,
          }),
          boxShadow: '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
        }}
      />

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Details user={user} />
        <Actions user={user} />
      </div>

      {showRemoveIcon &&
      <button
        type='button'
        style={{
          all: 'unset',
          position: 'absolute',
          top: -8,
          right: -8,
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
        onClick={handleShowRemoveModal}
      >
        <StarIcon color={Colors.secondary_500} />
      </button>
      }

      {showRemoveModal && (
        <ConfirmPopup
          title="Removal Confirmation"
          description={
            <>
              Please confirm that you would like to remove{' '}
              <b>{user.fullname}</b> from your Favorites List.
            </>
          }
          leftButtonText="Cancel"
          leftButtonAction={() => handleShowRemoveModal()}
          rightButtonText="Remove"
          rightButtonAction={() => {
            dispatch(removeFavoriteProfessional({ userId: user.id }));
            handleShowRemoveModal();
          }}
        />
      )}
    </motion.div>
  );
};
