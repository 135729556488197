import {
  call, put, take, all, fork, select,
} from 'redux-saga/effects';
import { convertTimeToMinutes } from '../components/Request/multiday/utils';
import * as actions from '../actions';
import { apiMethods, apiMethodsConst } from '../services/api';
import history from '../history';
import { logCustomEvent, trackEvent } from '../api/analytics';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
  return response;
}

function* callAndDispatchPayload(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload });
  return response
}

const JOB_LIMIT = 10;
function* watchCreateJob() {
  const events = actions.createRequestTypes(actions.CREATE_JOB_ACTION);
  while (true) {
    const data = yield take(actions.CREATE_JOB_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.CREATE_JOB_REQUEST, data);
      const { job } = yield select((store) => store.job);
      const { user } = yield select((store) => store.user);

      const is_info_asked = yield select(
        (store) => store.user.selectedChildOffice?.office_info?.is_info_asked,
      );
      yield logCustomEvent('job_post', data);
      yield put({ type: events.SUCCESS });

      trackEvent('Job Post', {
        state: user.state,
        zip: user.zip,
        professional: data.professional,
        rate: data.rate,
        date: data.localDate,
        start: data.localStart,
        end: data.localEnd,
        break: data.break_time,
        'break paid': data.is_break_paid,
        'prefer type': data.prefer_type,
        profession: data.profession,
      });

      const jobData = {
        office: job.offer_owner.office_name,
        date: job.local_date,
        profession: job.professionName,
        subProfession: job.subProfession,
        skills: job.skills,
        local_start_time: job.local_start_time,
        local_end_time: job.local_end_time,
        local_timezone: job.local_timezone,
        rate: job.rate,
        lunch_break_time: job.lunch_break_time,
        is_lunch_break_paid: job.is_lunch_break_paid,
      };

      yield put({
        type: actions.SHOW_CREATE_JOB_MESSAGE,
        payload: {
          route: !is_info_asked ? '/dashboard/nps' : `/dashboard/job/${job.id}`,
          params: { jobId: job.id, isJobPost: true },
          jobData,
        },
      });
    } catch (error) {
      if (error.response?.status === actions.DUPLICATE_JOB_ERROR) {
        yield put({ type: 'CREATE_JOB_ACTION_STOP_LOADING' });
        yield put({ type: actions.IS_OVERLAPPING_JOB, payload: error.response.data.message });
      } else if (error.response?.status === actions.NO_CARD_ERROR) {
        yield put({ type: 'CREATE_JOB_ACTION_STOP_LOADING' });
        yield put({ type: actions.SHOW_JOB_CREATE_PAYMENT_ERROR });
      } else {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
    }
  }
}

function* watchfetchJob() {
  const events = actions.createRequestTypes(actions.FETCH_JOB_ACTION);
  while (true) {
    const { jobId, candidateId, requestCandidateOvertime = true } = yield take(actions.FETCH_JOB_ACTION);

    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatch(apiMethodsConst.FETCH_JOB_REQUEST, jobId);

      if (requestCandidateOvertime && (response?.status === 'pending' || response?.status === 'counter') && candidateId) {
        yield* callAndDispatch(apiMethodsConst.FETCH_CANDIDATE_OVERTIME_REQUEST, {
          jobId,
          candidateId,
        })
      }

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchfetchCandidateJobOvertime() {
  const events = actions.createRequestTypes(actions.FETCH_CANDIDATE_JOB_OVERTIME_ACTION);
  while (true) {
    const { jobId, candidateId } = yield take(actions.FETCH_CANDIDATE_JOB_OVERTIME_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_CANDIDATE_OVERTIME_REQUEST, {
        jobId,
        candidateId,
      })
      
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetUserOvertimeLimits() {
  const events = actions.createRequestTypes(actions.GET_USER_OVERTIME_LIMITS_ACTION);
  while (true) {
    yield take(actions.GET_USER_OVERTIME_LIMITS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_USER_OVERTIME_LIMITS_REQUEST);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchAverageRating() {
  const events = actions.createRequestTypes(actions.FETCH_AVERAGE_RATING);
  while (true) {
    const { jobId } = yield take(actions.FETCH_AVERAGE_RATING);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_AVERAGE_RATING);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchEditJob() {
  const events = actions.createRequestTypes(actions.EDIT_JOB_ACTION);
  while (true) {
    try {
      const { jobData, jobId } = yield take(actions.EDIT_JOB_ACTION);

      yield put({ type: events.REQUEST });
      
      const { autoFillFavorites, autoFillHighlyRated, ...restOfJobData } = jobData;
      const response = yield* callAndDispatch(apiMethodsConst.EDIT_JOB_REQUEST, { 
        jobId, 
        jobData: {
          ...restOfJobData,
          autofillOptions: {
            favorite: autoFillFavorites,
            highlyRated: autoFillHighlyRated,
          },
        },
      });
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          message: 'The offer has been updated',
          route: 'back',
          isSuccess: true,
        },
      });
      yield put({ type: events.SUCCESS });

      trackEvent('Edit Job', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        rate: jobData?.rate,
        date: jobData?.localDate,
        start: jobData?.localStart,
        end: jobData?.localEnd,
        break: jobData?.break_time,
        'break paid': jobData?.is_break_paid,
        'prefer type': jobData?.prefer_type,
        profession: jobData?.profession,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetAllJob() {
  const events = actions.createRequestTypes(actions.GET_ALL_JOBS_ACTION);
  while (true) {
    const { start, end } = yield take(actions.GET_ALL_JOBS_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_ALL_JOBS_REQUEST, { start, end });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetAllJobByStatusDate() {
  const events = actions.createRequestTypes(actions.GET_JOBS_BY_STATUS_DATE_ACTION);
  while (true) {
    const { status } = yield take(actions.GET_JOBS_BY_STATUS_DATE_ACTION);
    const { jobListPagination } = yield select((store) => store.job);

    yield put({ type: events.REQUEST, payload: { status } });
    try {
      if (status?.status !== '') {
        const page = status?.enablePagination ? jobListPagination.page + 1 : 1;

        const response = yield call(apiMethods[apiMethodsConst.GET_JOBS_BY_STATUS_DATE_REQUEST], {
          ...status,
          page,
          date: status.date || jobListPagination?.date,
        });
        yield put({
          type: apiMethodsConst.GET_JOBS_BY_STATUS_DATE_REQUEST,
          payload: { jobs: response, ...status, page },
        });
      }
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchJobsWithTransactions() {
  const events = actions.createRequestTypes(actions.FETCH_JOBS_WITH_TRANSACTIONS);
  while (true) {
    const {
      page, limit, sort, order,
    } = yield take(actions.FETCH_JOBS_WITH_TRANSACTIONS);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_JOBS_WITH_TRANSACTIONS, {
        page,
        limit,
        sort,
        order,
      });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchCancelJob() {
  const events = actions.createRequestTypes(actions.CANCEL_JOB_ACTION);
  while (true) {
    const { jobId, redirect } = yield take(actions.CANCEL_JOB_ACTION);

    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatchPayload(
        apiMethodsConst.CANCEL_JOB_REQUEST,
        { jobId, redirect }
      );
      yield logCustomEvent('cancel_job', { jobId });
      yield put({ type: events.SUCCESS });

      trackEvent('Cancel Job', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        rate: response.data?.rate,
        date: response.data?.localDate,
        start: response.data?.localStart,
        end: response.data?.localEnd,
        break: response.data?.break_time,
        'break paid': response.data?.is_break_paid,
        profession: response.data?.profession,
      });

      if (redirect) {
        history.push(`/dashboard/feedback/${jobId}`);
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFecthCancellationReasons() {
  const events = actions.createRequestTypes(actions.FETCH_CANCELLATION_REASONS);
  while (true) {
    yield take(actions.FETCH_CANCELLATION_REASONS);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_CANCELLATION_REASONS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSetCancellationReasons() {
  const events = actions.createRequestTypes(actions.SUBMIT_CANCELLATION_REASON);
  while (true) {
    const { jobId, cancellationFeedback, cancellationReason } = yield take(
      actions.SUBMIT_CANCELLATION_REASON,
    );

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.SET_CANCELLATION_REASONS_REQUEST, {
        jobId,
        data: { cancellationFeedback, cancellationReason },
      });
      yield put({ type: events.SUCCESS });
      history.push('/dashboard/home');
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchConfirmJob() {
  const events = actions.createRequestTypes(actions.CONFIRM_JOB_ACTION);
  while (true) {
    const { jobId, data, counterOfferId, candidateId } = yield take(actions.CONFIRM_JOB_ACTION);

    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatch(apiMethodsConst.CONFIRM_JOB_REQUEST, {
        jobId,
        data,
        counterOfferId,
      });
      yield logCustomEvent('confirm_candidate', { ...data, jobId });
      const pendingJobs = yield select((state) => state.job.actionRequiredJobs);
      const nextJob = pendingJobs.find(
        (j) => j.id !== jobId && j.multipleCounters.find((c) => c.candidate.id === candidateId),
      );

      if (nextJob) {
        yield put({ type: actions.SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP })
      } else {
        yield put({
          type: actions.SHOW_MESSAGE,
          payload: {
            title: 'Good News!',
            message: 'Your shift has successfully been filled.',
            route: `/dashboard/jobs/${pendingJobs?.length > 0 ? "pending" : "scheduled"}`,
            isSuccess: true,
          },
        });
      }

      yield put({ type: events.SUCCESS });

      trackEvent('Confirm Candidate for Job', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        rate: response.data?.rate,
        date: response.data?.localDate,
        start: response.data?.localStart,
        end: response.data?.localEnd,
        break: response.data?.break_time,
        'break paid': response.data?.is_break_paid,
        profession: response.data?.profession,
      });
    } catch (error) {
      if (error.response?.status === actions.NO_CARD_ERROR) {
        yield put({ type: 'CONFIRM_JOB_ACTION_STOP_LOADING' });
        yield put({ type: actions.SHOW_PAYMENT_MISSING_POPUP });
      } else {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
    }
  }
}

function* watchUpdateJob() {
  const events = actions.createRequestTypes(actions.UPDATE_JOB_ACTION);
  while (true) {
    const { jobData } = yield take(actions.UPDATE_JOB_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield put({ type: events.SUCCESS, payload: jobData });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchReportIncident() {
  const events = actions.createRequestTypes(actions.REPORT_INCIDENT_ACTION);

  try {
    const data = yield take(actions.REPORT_INCIDENT_ACTION);
    const { jobId, incidentType } = data;
    yield put({ type: events.REQUEST });

    yield* callAndDispatch(apiMethodsConst.REPORT_INCIDENT_REQUEST, { jobId, type: incidentType });
    yield put({ type: events.SUCCESS, payload: jobId });
    yield put({ type: actions.SHOW_REPORT_INCIDENT_SUCCESS_POPUP, payload: data });
  } catch (error) {
    yield put({ type: events.FAILURE, payload: error });
  }
}

function* watchCloseOverlappingModal() {
  const events = actions.createRequestTypes(actions.CLOSE_OVERLAPPING_MODAL);

  try {
    yield take(actions.CLOSE_OVERLAPPING_MODAL);
    yield put({ type: events.REQUEST });

    yield put({ type: events.SUCCESS });
  } catch (error) {
    yield put({ type: events.FAILURE, payload: error });
  }
}

function* watchMultiDayPosting() {
  const events = actions.createRequestTypes(actions.MULTIDAY_BOOKING_POST);
  while (true) {
    yield take(actions.MULTIDAY_BOOKING_POST);

    yield put({ type: events.REQUEST });
    try {
      const jobInfo = yield select((state) => state.multiday.jobInfo.map(({ 
        autoFillFavorites, 
        autoFillHighlyRated,
        ...restOfJob 
      }) => ({
        ...restOfJob,
        break_time: convertTimeToMinutes(restOfJob.break_time),
        rate: parseFloat(restOfJob.rate),
        autoFill: state.job.showJobCreatePaymentError ? false : restOfJob.autoFill,
        autofillOptions: {
          favorite: state.job.showJobCreatePaymentError ? false : autoFillFavorites,
          highlyRated: state.job.showJobCreatePaymentError ? false : autoFillHighlyRated,
        }
      })));

      yield* callAndDispatch(apiMethodsConst.MULTIDAY_JOB_POSTING_REQUEST, jobInfo);
      yield put({ type: events.SUCCESS });
      yield logCustomEvent('multiday_post', jobInfo);
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: 'Request Successfully Sent!',
          message:
            'We’re reaching out to all professionals in your area. Your shifts were sent successfully to the GoTu community. You will be notified when your offers have been accepted.',
          route: '/',
          isSuccess: true,
        },
      });
    } catch (error) {
      if (error.response?.status === actions.DUPLICATE_JOB_ERROR) {
        yield put({ type: actions.IS_OVERLAPPING_JOB, payload: error.response.data.message });
        yield put({ type: 'MULTIDAY_BOOKING_POST_STOP_LOADING' });
      } else if (error.response?.status === actions.NO_CARD_ERROR) {
        yield put({ type: actions.SHOW_JOB_CREATE_PAYMENT_ERROR });
        yield put({ type: 'MULTIDAY_BOOKING_POST_STOP_LOADING' });
      } else {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
    }
  }
}

function* watchInviteToMultiDayPosting() {
  const events = actions.createRequestTypes(actions.MULTIDAY_BOOKING_INVITE_POST);
  while (true) {
    yield take(actions.MULTIDAY_BOOKING_INVITE_POST);

    yield put({ type: events.REQUEST });
    try {
      const jobInfo = yield select((state) => state.multiday.jobInfo.map(({ 
        autoFillFavorites, 
        autoFillHighlyRated,
        ...restOfJob 
      }) => ({
        ...restOfJob,
        break_time: convertTimeToMinutes(restOfJob.break_time),
        rate: parseFloat(restOfJob.rate),
        autoFill: state.job.showJobCreatePaymentError ? false : restOfJob.autoFill,
        autofillOptions: {
          favorite: state.job.showJobCreatePaymentError ? false : autoFillFavorites,
          highlyRated: state.job.showJobCreatePaymentError ? false : autoFillHighlyRated,
        }
      })));

      const professionalName = jobInfo[0].professional?.name;

      const response = yield* callAndDispatch(
        apiMethodsConst.MULTIDAY_JOB_POSTING_REQUEST, jobInfo
      );
      const professionalId = jobInfo?.[0]?.professional?.id;
      yield* callAndDispatch(apiMethodsConst.INVITE_PROFESSIONALS_TO_JOBS_REQUEST, { 
        professionalIds: [professionalId], jobIds: response?.jobs
      });
      yield put({ type: events.SUCCESS });
      yield logCustomEvent('rebook_post', jobInfo);
      trackEvent('Rebooking Job', {
        professionalId,
        numberOfJobs: response?.jobs?.length,
      })
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: 'Request & Invite(s) Successfully Sent!',
          message: (
            <span>
              Your shifts were sent successfully to the GoTu community and{' '}
              <b>{professionalName}</b> has received invites for the days they are available.
              Please note: {professionalName?.split(' ')[0]} will not receive
              your invite(s) for days that their availability is turned off.
            </span>
          ),
          route: '/',
          isSuccess: true,
        },
      });
    } catch (error) {
      if (error.response?.status === actions.DUPLICATE_JOB_ERROR) {
        yield put({ type: actions.IS_OVERLAPPING_JOB, payload: error.response.data.message });
        yield put({ type: 'MULTIDAY_BOOKING_INVITE_POST_STOP_LOADING' });
      } else if (error.response?.status === actions.NO_CARD_ERROR) {
        yield put({ type: actions.SHOW_JOB_CREATE_PAYMENT_ERROR });
        yield put({ type: 'MULTIDAY_BOOKING_INVITE_POST_STOP_LOADING' });
      } else {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
    }
  }
}

function* watchPPPosting() {
  const events = actions.createRequestTypes(actions.POST_PP_JOB);
  while (true) {
    const { jobData } = yield take(actions.POST_PP_JOB);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.PP_POSTING_REQUEST, jobData);
      yield put({ type: events.SUCCESS });
      yield logCustomEvent('permanent_post', jobData);
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: 'Request Successfully Submitted!',
          message: 'Your Permanent Hire request has been submitted.',
          route: '/',
          isSuccess: true,
        },
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSelectDatesMDB() {
  const events = actions.createRequestTypes(actions.SELECT_DAYS_MDB);
  while (true) {
    yield take(actions.SELECT_DAYS_MDB);
  }
}

function* watchJobInfoMDB() {
  const events = actions.createRequestTypes(actions.SELECT_DEFAULT_INFO_MDB);

  while (true) {
    yield take(actions.SELECT_DEFAULT_INFO_MDB);
  }
}

function* watchOpenJobs() {
  const events = actions.createRequestTypes(actions.FETCH_OPEN_JOBS_ACTION);

  while (true) {
    const { page } = yield take(actions.FETCH_OPEN_JOBS_ACTION);

    try {
      const { dashboardJobListPagination } = yield select((store) => store.job);
      const state = {
        page,
        limit: JOB_LIMIT,
      };
      const oldState = {
        page: dashboardJobListPagination.page, limit: dashboardJobListPagination.limit,
      };
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(oldState) !== JSON.stringify(state))
        || !dashboardJobListPagination.isActiveRequest
        || !dashboardJobListPagination.isFinal
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_OPEN_JOB_REQUEST, state);

        yield put({ type: events.SUCCESS });

        const openJobs = yield select((store) => store.job.openJobs);
        trackEvent('Shifts - Open Shifts Tab Clicked', { openShiftsCount: openJobs?.length });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data || error });
    }
  }
}

function* watchScheduledJobs() {
  const events = actions.createRequestTypes(actions.FETCH_SCHEDULED_JOBS_ACTION);
  while (true) {
    const { page } = yield take(actions.FETCH_SCHEDULED_JOBS_ACTION);

    try {
      const { dashboardJobListPagination } = yield select((store) => store.job);
      const state = {
        page,
        limit: JOB_LIMIT,
      };
      const oldState = {
        page: dashboardJobListPagination.page, limit: dashboardJobListPagination.limit,
      };
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(oldState) !== JSON.stringify(state))
        || !dashboardJobListPagination.isActiveRequest
        || !dashboardJobListPagination.isFinal
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_SCHEDULED_JOBS_REQUEST, state);
        yield put({ type: events.SUCCESS });

        const scheduledJobs = yield select((store) => store.job.scheduledJobs);
        trackEvent('Shifts - Scheduled Shifts Tab Clicked', { scheduledShiftsCount: scheduledJobs?.length })
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data || error });
    }
  }
}

function* watchActionRequiredJobs() {
  const events = actions.createRequestTypes(actions.FETCH_ACTION_REQUIRED_JOBS_ACTION);
  while (true) {
    const { page } = yield take(actions.FETCH_ACTION_REQUIRED_JOBS_ACTION);

    try {
      const { dashboardJobListPagination } = yield select((store) => store.job);
      const state = {
        page,
        limit: JOB_LIMIT,
      };
      const oldState = {
        page: dashboardJobListPagination.page, limit: dashboardJobListPagination.limit,
      };
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(oldState) !== JSON.stringify(state))
        || !dashboardJobListPagination.isActiveRequest
        || !dashboardJobListPagination.isFinal
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_ACTION_REQUIRED_JOBS_REQUEST, state);
        yield put({ type: events.SUCCESS });

        const pendingJobs = yield select((store) => store.job.actionRequiredJobs);
        trackEvent('Shifts - Action Needed Tab Clicked', { actionNeededShiftsCount: pendingJobs?.length })
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchActionTodayJobs() {
  const events = actions.createRequestTypes(actions.FETCH_TODAY_JOBS_ACTION);
  while (true) {
    const { page } = yield take(actions.FETCH_TODAY_JOBS_ACTION);

    try {
      const { dashboardJobListPagination } = yield select((store) => store.job);
      const state = {
        page,
        limit: JOB_LIMIT,
      };
      const oldState = {
        page: dashboardJobListPagination.page, limit: dashboardJobListPagination.limit,
      };
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(oldState) !== JSON.stringify(state))
        || !dashboardJobListPagination.isActiveRequest
        || !dashboardJobListPagination.isFinal
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_TODAY_JOBS_REQUEST, state);

        yield put({ type: events.SUCCESS });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchDeclineCounterOffer() {
  const events = actions.createRequestTypes(actions.DECLINE_COUNTER_OFFER);
  while (true) {
    const { jobId, counterOfferId } = yield take(actions.DECLINE_COUNTER_OFFER);

    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatch(apiMethodsConst.DECLINE_COUNTER_OFFER_REQUEST, {
        jobId,
        counterOfferId,
      });
      yield logCustomEvent('decline_counter_offer', {
        jobId,
        counterOfferId,
      });

      trackEvent('Decline Counter offer', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        rate: response.data?.rate,
        date: response.data?.localDate,
        start: response.data?.localStart,
        end: response.data?.localEnd,
      });

      history.push(`/dashboard/job/${jobId}`);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchDeclineCandidate() {
  const events = actions.createRequestTypes(actions.DECLINE_CANDIDATE);
  while (true) {
    const { jobId, counterOfferId, cancellation_reason } = yield take(actions.DECLINE_CANDIDATE);
    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatch(apiMethodsConst.DECLINE_COUNTER_OFFER_REQUEST, {
        jobId,
        counterOfferId,
        cancellation_reason,
      });
      yield logCustomEvent('decline_candidate', {
        jobId,
        counterOfferId,
      });
      yield put({ type: events.SUCCESS });

      trackEvent('Decline Candidate', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        rate: response.data?.rate,
        date: response.data?.localDate,
        start: response.data?.localStart,
        end: response.data?.localEnd,
      });

      history.replace(`/dashboard/job/${jobId}`);
    } catch (error) {
      if (error.response?.status === actions.NO_CARD_ERROR) {
        yield put({ type: 'DECLINE_CANDIDATE_STOP_LOADING' });
        yield put({ type: actions.SHOW_PAYMENT_MISSING_POPUP });
      } else {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
    }
  }
}

function* watchFetchCounterOffers() {
  const events = actions.createRequestTypes(actions.FETCH_EXPIRED_COUNTER_OFFERS);
  while (true) {
    const { jobId } = yield take(actions.FETCH_EXPIRED_COUNTER_OFFERS);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_EXPIRED_COUNTER_OFFERS, jobId);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchDashboardInfo() {
  const events = actions.createRequestTypes(actions.FETCH_DASHBOARD_INFO);
  while (true) {
    yield take(actions.FETCH_DASHBOARD_INFO);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_DASHBOARD_INFO);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchReceipts() {
  const events = actions.createRequestTypes(actions.FETCH_RECEIPT_LIST);
  while (true) {
    const { state } = yield take(actions.FETCH_RECEIPT_LIST);
    try {
      const { receiptsPagination } = yield select((store) => store.job);
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(receiptsPagination) !== JSON.stringify(state))
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_RECEIPTS_REQUEST, state);

        yield put({
          type: events.SUCCESS,
        });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}
function* watchFetchReceiptsReport() {
  const events = actions.createRequestTypes(actions.FETCH_RECEIPTS_REPORT);
  while (true) {
    try {
      const { state } = yield take(actions.FETCH_RECEIPTS_REPORT);

      yield put({ type: events.REQUEST, payload: state });

      yield* callAndDispatch(apiMethodsConst.FETCH_RECEIPTS_REPORT_REQUEST, state);

      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchChangeCalendarTabs() {
  const events = actions.createRequestTypes(actions.CHANGE_CALENDAR_TABS);
  while (true) {
    yield take(actions.CHANGE_CALENDAR_TABS);
  }
}

function* watchChangeSchedule() {
  const events = actions.createRequestTypes(actions.CHANGE_SCHEDULE);
  while (true) {
    yield take(actions.CHANGE_SCHEDULE);
  }
}

function* watchAdjustmentRequest() {
  const events = actions.createRequestTypes(actions.ADJUST_HOURS_ACTION);
  while (true) {
    const { jobId, data, navigateToRating } = yield take(actions.ADJUST_HOURS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      const response = yield* callAndDispatch(apiMethodsConst.ADJUST_HOURS_REQUEST, {
        jobId,
        data,
      });
      yield logCustomEvent('adjustment_request', {
        jobId,
        data,
      });
      yield put({ type: events.SUCCESS, payload: jobId });

      trackEvent('Adjust Job', {
        state: response.data?.state,
        zip: response.data?.zip,
        jobId,
        'new date': data.localDate,
        'new break': data.break,
        'new start time': data.localStart,
        'new end time': data.localEnd,
      });

      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: 'Adjustment Request Submitted',
          message:
            'Your adjustment has been received and sent to the professional for review. Once they confirm this reported time adjustment, all charges related to this shift will be adjusted accordingly.',
          route: navigateToRating ? `/dashboard/job/${jobId}/feedback-rating` : 'back',
          isSuccess: true,
        },
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchInvoices() {
  const events = actions.createRequestTypes(actions.FETCH_INVOICE_LIST);
  while (true) {
    const { state } = yield take(actions.FETCH_INVOICE_LIST);
    try {
      const { invoicesPagination } = yield select((store) => store.job);
      if (
        state.page === 1
        || (JSON.stringify(state) && JSON.stringify(invoicesPagination) !== JSON.stringify(state))
      ) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.FETCH_INVOICES_REQUEST, state);

        yield put({
          type: events.SUCCESS,
        });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchCreateLocumTenens() {
  const events = actions.createRequestTypes(actions.CREATE_LOCUM_TENENS_DENTIST_ACTION);
  while (true) {
    const { text } = yield take(actions.CREATE_LOCUM_TENENS_DENTIST_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.CREATE_LOCUM_TENENS_DENTIST_REQUEST, text);
      yield put({ type: events.SUCCESS });
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: 'Request Successfully Sent!',
          message:
            'We’ve received your request. Someone from GoTu will reach out shortly with next steps.',
          route: '/dashboard/locum-tenens-dentist',
          isSuccess: true,
        },
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchOpenJobsForRecentProfessional() {
  const events = actions.createRequestTypes(actions.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION);
  while (true) {
    const { 
      page, 
      professionalId, 
      profession 
    } = yield take(actions.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION);

    try {
      const { recentOpenJobs } = yield select((store) => store.job);
      if (
        page === 1
        || page !== recentOpenJobs.page
        || !recentOpenJobs.isActiveRequest
        || !recentOpenJobs.isFinal
      ) {
        yield put({ type: events.REQUEST });
        const method = apiMethodsConst.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_REQUEST;
        const response = yield call(apiMethods[method], {
          professionalId,
          profession,
          limit: recentOpenJobs.limit,
          page,
        });
        yield put({ type: method, payload: { ...response, professionalId } });
        yield put({ type: events.SUCCESS });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data || error });
    }
  }
}

function* watchInviteProfessionalsToJobs() {
  const events = actions.createRequestTypes(actions.INVITE_PROFESSIONALS_TO_JOBS);
  while (true) {
    const { professionalIds, jobIds } = yield take(actions.INVITE_PROFESSIONALS_TO_JOBS);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.INVITE_PROFESSIONALS_TO_JOBS_REQUEST, { 
        professionalIds, jobIds 
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchOfficeJobInvites() {
  const events = actions.createRequestTypes(actions.FETCH_OFFICE_JOB_INVITES_ACTION);

  while (true) {
    const { page } = yield take(actions.FETCH_OFFICE_JOB_INVITES_ACTION);

    try {
      const { officeJobInvites } = yield select((store) => store.job);
      const params = {
        page,
        limit: 10,
      };
      const oldParams = {
        page: officeJobInvites.page, limit: officeJobInvites.limit,
      };
      if (
        params.page === 1
        || (JSON.stringify(params) && JSON.stringify(params) !== JSON.stringify(oldParams))
        || !officeJobInvites.isActiveRequest
        || !officeJobInvites.isFinal
      ) {
        yield put({ type: events.REQUEST, payload: params });
        yield* callAndDispatch(apiMethodsConst.FETCH_OFFICE_JOB_INVITES_REQUEST, params);
        yield put({ type: events.SUCCESS });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data || error });
    }
  }
}

export default function* sagaJob() {
  yield all([
    fork(watchCreateJob),
    fork(watchfetchJob),
    fork(watchAverageRating),
    fork(watchCancelJob),
    fork(watchEditJob),
    fork(watchConfirmJob),
    fork(watchUpdateJob),
    fork(watchGetAllJob),
    fork(watchGetAllJobByStatusDate),
    fork(watchFetchJobsWithTransactions),
    fork(watchReportIncident),
    fork(watchCloseOverlappingModal),
    fork(watchMultiDayPosting),
    fork(watchPPPosting),
    fork(watchSelectDatesMDB),
    fork(watchJobInfoMDB),
    fork(watchOpenJobs),
    fork(watchScheduledJobs),
    fork(watchActionRequiredJobs),
    fork(watchActionTodayJobs),
    fork(watchChangeCalendarTabs),
    fork(watchChangeSchedule),
    fork(watchDeclineCounterOffer),
    fork(watchDeclineCandidate),
    fork(watchFetchCounterOffers),
    fork(watchFetchDashboardInfo),
    fork(watchFetchReceipts),
    fork(watchAdjustmentRequest),
    fork(watchFetchReceiptsReport),
    fork(watchFetchInvoices),
    fork(watchFecthCancellationReasons),
    fork(watchSetCancellationReasons),
    fork(watchCreateLocumTenens),
    fork(watchGetUserOvertimeLimits),
    fork(watchFetchOpenJobsForRecentProfessional),
    fork(watchInviteProfessionalsToJobs),
    fork(watchFetchOfficeJobInvites),
    fork(watchInviteToMultiDayPosting),
    fork(watchfetchCandidateJobOvertime),
  ]);
}
