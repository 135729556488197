import { AnimatePresence, motion } from 'framer-motion';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import { Colors } from '../../../../themes/colors';
import { RemoteConfigUtils } from '../../../../utils/RemoteConfigUtils';
import { CardActionsHeader } from '../CardActionsHeader';
import { NewHighlyRatedTabPopup } from '../NewHighlyRatedTabPopup';
import { ProfessionalCard } from '../ProfessionalCard';
import { EmptyHighlyRated } from './EmptyHighlyRated';
import { useHighlyRatedList } from './hooks/useHighlyRatedList';
import { ProfessionalCardActions } from './ProfessionalCardActions';
import { ProfessionalCardDetails } from './ProfessionalCardDetails';

export const HighlyRatedList = () => {
  const { data, isLoading, handleScrollList, isScrollLoading } = useHighlyRatedList();

  if (isLoading) {
    return <CustomSpinner isLoading size={24} />;
  }

  if (!data?.length) {
    return (
      <>
        <NewHighlyRatedTabPopup />
        <EmptyHighlyRated />
      </>
    );
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'relative',
          fontFamily: 'Nunito',
          fontSize: '16px',
          fontStyle: 'italic',
          color: Colors.neutral_500,
          textAlign: 'center',
          width: 600,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        This section of the Professionals Hub shows a list professionals that your office rated
        above a {RemoteConfigUtils.getMinimumHighlyRatedProfessionalRating()} on your last review
        for this professional.
      </div>

      <div
        style={{
          maxWidth: '827px',
          marginTop: 32,
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: 600,
          overflowY: 'auto',
        }}
        onScroll={handleScrollList}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
          layout
        >
          <AnimatePresence>
            <CardActionsHeader actions={['Invite', 'Favorite']} />
            {data?.map((item, index) => (
              <ProfessionalCard
                key={item?.professional?.id}
                user={{
                  id: item?.professional?.id,
                  fullname: item?.professional?.fullName,
                  profileImage: item.professional?.profileImage,
                  profession: item?.job?.profession,
                  datetime: item?.job?.dateTimeEnd,
                  rating:
                    !item?.professionalRating && !item?.ratingCount
                      ? null
                      : {
                        averageRating:
                            item?.professionalRating?.averageRating || item?.ratingCount,
                        interpersonalSkills:
                            item?.professionalRating?.interpersonalSkills || item?.ratingCount,
                        professionalSkills:
                            item?.professionalRating?.professionalSkills || item?.ratingCount,
                        timeManagement:
                            item?.professionalRating?.timeManagement || item?.ratingCount,
                      },
                  jobId: item?.job?.id,
                  isAvailable: true,
                }}
                Details={({ user }) => (
                  <ProfessionalCardDetails
                    user={user}
                    popoverPosition={data.length > 3 && index + 3 > data.length ? 'top' : 'bottom'}
                  />
                )}
                Actions={ProfessionalCardActions}
                showRemoveIcon={false}
              />
            ))}
          </AnimatePresence>
          <div>
            <CustomSpinner isLoading={isScrollLoading} size={32} style={{ marginTop: 12 }} />
          </div>
        </motion.div>
      </div>

      <NewHighlyRatedTabPopup />
    </div>
  );
};
